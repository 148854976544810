import ReactPaginate from "react-paginate";
import { Link, useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";

import "./style.css";
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout } from '../redux/controller';
import 'react-toastify/dist/ReactToastify.css';
import { format } from 'date-fns';


export default function HuntersDetails(props) {
  const location = useLocation();
  const { state } = location;
  const { id, hunter_name, email, phone_number, createdAt } = state || {};

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [sortConfig, setSortConfig] = useState({
    key: "",
    direction: ""
  });
  const [searchInput, setSearchInput] = useState("");

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [hunterId, setHunterId] = useState("");
  const [hunterName, setHunterName] = useState("");
  const [hunterEmail, setHunterEmail] = useState("");
  const [hunterPhoneNumber, setHunterPhoneNumber] = useState("");
  const [hinterCreatedAt, setHunterCreatedAt] = useState("");

  const [searchResults, setSearchResults] = useState([]);

  // const usertoken = useSelector((state) => state.userdata.usertoken);
  const usertoken = localStorage.getItem('usertoken');

  const [status, setStatus] = useState('Loading...');
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      if (id) {
        setHunterId(id)
        setHunterName(hunter_name)
        setHunterEmail(email)
        setHunterPhoneNumber(phone_number)
        setHunterCreatedAt(createdAt)

        getAllHunters()
      } else {
        navigate('/')
      }
      return () => {
        effectRan.current = true;
      };
    }
  }, [dispatch, usertoken, navigate]);

  const getAllHunters = async (searchQuery = "") => {

    setSearchResults([]);
    setStatus('Loading...')
    var reservationList = await dispatch(
      fetchData({
        apiurl: "/reservation/allhunterreservations",
        page: 1,
        hunterId: id ?? hunterId,
        limit: 10000,
        search: searchQuery,
        headers: { "x-access-token": usertoken }
      })
    );

    if (reservationList && reservationList.payload && reservationList.payload.status === 200) {
      if (
        reservationList.payload.reservations &&
        reservationList.payload.reservations.length
      ) {
        setStatus('');
        const huntersListLocal = reservationList.payload.reservations.map((reservations) => {

          const selectedDate = convertdate(reservations.reservations.cart[0].selecteddate);
          const formattedDate = convertdate(reservations.reservations.cart[0].selecteddate);
          // const formattedDate = `${String(selectedDate.getDate()).padStart(2, '0')}/${String(selectedDate.getMonth() + 1).padStart(2, '0')}/${selectedDate.getFullYear()}`;

          const paymentAmount = reservations?.reservations?.payment?.paymentdata?.paymentAmount;
          const total = (paymentAmount == null || paymentAmount === '') ? '0' : paymentAmount;

          const paymentId = reservations.reservations?.payment?.paymentdata?.paymenttId;
          const id = (paymentId == null || paymentId === '') ? '--' : paymentId;

          return {
            id: reservations.reservations._id,
            payment_id: id,
            cart: reservations.reservations.cart,
            hunter_name: reservations.hunter?.username ?? '--',
            landowner_name: reservations.landowner?.firstname + ' ' + reservations.landowner.lastname ?? '--',
            property_name: reservations.reservations.cart[0].property.title,
            reservation_date: formattedDate,
            slot_id: reservations.reservations.cart[0].selectedslot.name,
            cost: reservations.reservations.paymentdetail.total ?? '0',
            coupon_discount: reservations.reservations?.couponapplied?.couponamount ?? '--',
            coupon_discount_type: reservations.reservations?.couponapplied?.discountType ?? "--",
            coupon_applied: reservations.reservations?.couponapplied?.couponcode ?? "--",
            total: total,
            reservation_id: reservations.reservations._id,
            orderstatus: reservations.reservations.orderstatus,
            createdAt: format(reservations.reservations.createdAt.substring(0, 10), 'MM/dd/yyyy'),
            createdAts: reservations.reservations.createdAt,
            action: "cancel"
          };
        });
        setSearchResults(huntersListLocal);
      } else {
        setStatus('No reservations!');
      }
    } else {
      if (reservationList.payload.response && reservationList.payload.response.message) {
        if (reservationList.payload.response.message === "Invalid token provided!") {
          toastify({ message: 'Session expired' });
          userlogout();
          navigate('/');
        }
      }
    }
  }

  const handlereservationupcomingidPageClick = (selectedPage) => {
    setReservationupcomingidcurrentPage(selectedPage.selected);
  };

  const [
    reservationupcomingidcurrentPage,
    setReservationupcomingidcurrentPage
  ] = useState(0);
  const reservationupcomingidperPage = 10;
  const reservationupcomingidstartIndex =
    reservationupcomingidcurrentPage * reservationupcomingidperPage;
  const reservationupcomingidendIndex =
    reservationupcomingidstartIndex + reservationupcomingidperPage;

  const reservationupcomingidcurrentData = searchResults.slice(
    reservationupcomingidstartIndex,
    reservationupcomingidendIndex
  );

  const handleSearch = () => {
    getAllHunters(searchInput);
  };

  const handleClearSearch = () => {
    setSearchInput("");
    getAllHunters();
  };

  function gotopropertydetail(reservation, today) {
    console.log(reservation)
    var element = reservation.cart[0]
    var url = today ? "/propertydetailsmap?index=" + reservation.id + "&all=" + element.property._id : "/propertydetailsmap?index=" + reservation.id
    navigate(url, {
      state: {
        property: element.property,
        id: element.property.id,
        property_name: element.property.title,
        category: element.property.category,
        stateLocal: element.property.state,
        county: element.property.country,
        city: element.property.city,
        zipcode: element.property.zipcode,
        totalarea: element.property.totalarea,
        action: element.property.approved == null ? 'Pending' : element.property.approved === "false" ? 'Rejected' : element.property.approved === "paused" ? 'Paused' : 'Approved',
        landownerId: element?.property.userid
      }
    })
  }

  function sortreservations(type) {
    let direction = "ascending";
    if (sortConfig.key === type && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    console.log("type")
    console.log(type)
    console.log(direction)
    setSortConfig({ key: type, direction });
  }

  function convertdate(date) {
    if (date) {
      return date.substring(5, 7) + "/" + date.substring(8, 10) + "/" + date.substring(0, 4)
    }
    else {
      return date
    }
  }

  const getCurrentPageData = () => {
    console.log(reservationupcomingidcurrentData)
    const filteredData = reservationupcomingidcurrentData.sort((a, b) => {
      switch (sortConfig.key) {
        case "hunter_name":
          return sortConfig.direction === "ascending"
            ? a.hunter_name.localeCompare(b.hunter_name)
            : b.hunter_name.localeCompare(a.hunter_name);
        case "landowner_name":
          return sortConfig.direction === "ascending"
            ? a.landowner_name.localeCompare(b.landowner_name)
            : b.landowner_name.localeCompare(a.landowner_name);
        case "property_name":
          return sortConfig.direction === "ascending"
            ? a.property_name.localeCompare(b.property_name)
            : b.property_name.localeCompare(a.property_name);
        // case "invite_sent_date":
        //   return sortConfig.direction === "ascending"
        //     ? new Date(a.invite_sent_date) - new Date(b.invite_sent_date)
        //     : new Date(b.invite_sent_date) - new Date(a.invite_sent_date);
        case "createdAt":
          return sortConfig.direction === "ascending"
            ? new Date(a.createdAt) -
            new Date(b.createdAt)
            : new Date(b.createdAt) -
            new Date(a.createdAt);
        case "ReservationDate":
          return sortConfig.direction === "ascending"
            ? new Date(a.cart[0].selecteddate) -
            new Date(b.cart[0].selecteddate)
            : new Date(b.cart[0].selecteddate) -
            new Date(a.cart[0].selecteddate);
        default:
          return 0;
      }
    });
    console.log(filteredData)
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return filteredData.slice(startIndex, endIndex);
  }

  return (
    <div className="hunter-ditailsdiv">
      <div className="reservation-idtable">
        <div className="search-div">
          <div className="input-wrapper">
            <input
              type="text"
              className="form-control hunter-search"
              placeholder="Landowner Name, Property Name"

              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            {searchInput && (
              <button className="clear-btn" onClick={handleClearSearch}>×</button>
            )}
          </div>
          <button className="add-btn" onClick={handleSearch}>Search</button>
        </div>

        <div className="reservation_divider">
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Name</h1>
              <p>{hunterName}</p>
            </div>
          </div>
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Email ID</h1>
              <p>{hunterEmail}</p>
            </div>
          </div>
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Phone Number</h1>
              <p>{hunterPhoneNumber}</p>
            </div>
          </div>
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Account Created</h1>
              {/* <p>{`${new Date(hinterCreatedAt).toLocaleDateString()}`}</p> */}
              <p>
                {/* {new Date(hinterCreatedAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })} */}
                {convertdate(hinterCreatedAt)}
              </p>
            </div>
          </div>
        </div>
        <div className="add-form pagination-table">
          <div className="add-table">
            <table className="table">
              <thead>
                <tr>
                  <th onClick={() => sortreservations('landowner_name')}>Landowner &nbsp;
                    {sortConfig.key === "landowner_name" ? (
                      sortConfig.direction === "ascending" ? (
                        <i className="fas fa-arrow-up"></i>
                      ) : (
                        <i className="fas fa-arrow-down"></i>
                      )
                    ) : (
                      <i className="fas fa-arrow-down"></i>
                    )}</th>
                  <th onClick={() => sortreservations('property_name')}>Property Name &nbsp;
                    {sortConfig.key === "property_name" ? (
                      sortConfig.direction === "ascending" ? (
                        <i className="fas fa-arrow-up"></i>
                      ) : (
                        <i className="fas fa-arrow-down"></i>
                      )
                    ) : (
                      <i className="fas fa-arrow-down"></i>
                    )}</th>
                  <th onClick={() => sortreservations('createdAt')}>Purchased Date &nbsp;
                    {sortConfig.key === "createdAt" ? (
                      sortConfig.direction === "ascending" ? (
                        <i className="fas fa-arrow-up"></i>
                      ) : (
                        <i className="fas fa-arrow-down"></i>
                      )
                    ) : (
                      <i className="fas fa-arrow-down"></i>
                    )}</th>

                  <th onClick={() => sortreservations('ReservationDate')}>Reservation Date &nbsp;
                    {sortConfig.key === "ReservationDate" ? (
                      sortConfig.direction === "ascending" ? (
                        <i className="fas fa-arrow-up"></i>
                      ) : (
                        <i className="fas fa-arrow-down"></i>
                      )
                    ) : (
                      <i className="fas fa-arrow-down"></i>
                    )}</th>

                  <th>Slot Id</th>
                  <th>Cost</th>
                  <th>Coupon Applied</th>
                  <th>Coupon Discount</th>
                  <th>Total</th>
                  <th>Payment Id</th>
                  {/* <th>Status</th> */}
                </tr>
              </thead>
              <tbody>

                {getCurrentPageData().length === 0 ?
                  <td colSpan="9" style={{ textAlign: 'center' }}>
                    <div style={{
                      paddingLeft: '10px',
                      paddingTop: '40px',
                      display: 'inline-block',
                    }}>
                      {status}
                    </div>
                  </td>
                  :
                  getCurrentPageData().map((reservation) => (
                    <tr key={reservation.id} className="upcoming_cancel">
                      <td>{reservation.landowner_name}</td>
                      <td onClick={() => gotopropertydetail(reservation, reservation.createdAts)}><Link>{reservation.property_name}</Link></td>
                      <td>{reservation.createdAt}</td>
                      {/* 
                      <td>{reservation.slot_id}</td> */}
                      {/* <td>{reservation.reservation_date}</td> */}
                      {/* <td>{reservation.cart.map((name) => ( <p>{name.selecteddate.substring(0, 10)}</p> ))}</td> */}
                      <td>{reservation.cart.map((name) => (<p>
                        {convertdate(name.selecteddate)}
                        {/* {
                    new Date(name.selecteddate).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            })} */}
                      </p>))}</td>
                      {/* <td>{reservation.slot_id}</td> */}
                      <td>{reservation.cart.map((name) => (<p onClick={() => gotopropertydetail(reservation, false)}><Link className="hunter-link" >{name.selectedslot.name}</Link></p>))}</td>
                      <td>${reservation.cost}</td>
                      <td>{reservation.coupon_applied}</td>
                      {
                        reservation.coupon_discount_type === '--' ?
                          <td>--</td>
                          :
                          reservation.coupon_discount_type === 'percentage' ?
                            <td>{reservation.coupon_discount}%</td>

                            :
                            <td>${reservation.coupon_discount}</td>
                      }
                      <td>${reservation.total}</td>
                      <td>{reservation.payment_id}</td>
                      {/* <td>{reservation.orderstatus}</td> */}
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            pageCount={Math.ceil(
              searchResults.length / reservationupcomingidperPage
            )}
            pageRangeDisplayed={10}
            breakLabel={"..."}
            marginPagesDisplayed={2}
            onPageChange={handlereservationupcomingidPageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
}
