import React, { useState, useEffect } from "react";
import "./style.css";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../redux/apiSlice';
import Xicon from "../assets/Delete Icon.svg";
import { toastify } from "../redux/controller";

export default function ReservationDetails() {
  const dispatch = useDispatch();

  const { id } = useParams();

  const [reservationdetailsUpdated, setReservationData] = useState();
  const [step1inputField, setstep1InputField] = useState({
    cancelreason: ""
  })
  const [cancelSlotName, setCancelledSlotName] = useState('');
  const [cancelSlotDate, setCancelledSlotDate] = useState('');

  const [loading, setLoading] = useState(true);

  const [showLoader, setShowLoading] = useState(false);

  const usertoken = useSelector((state) => state.userdata.usertoken);

  useEffect(() => {
    getReservationDetails();
  }, [])

  useEffect(() => {
    if (reservationdetailsUpdated) {
      setLoading(false);
    }
  }, [reservationdetailsUpdated]);


  const cancelTheSlot = async () => {
    if(showLoader){
      return
    }
    setShowLoading(true)
    const cancelledreservation = await dispatch(fetchData({
      apiurl: "/reservation/cancelslotbooking",
      reservationId: reservationdetailsUpdated._id,
      isSlotCancelled: true,
      selecteddate: cancelSlotDate,
      selectedslotname: cancelSlotName,
      cancellationreason: step1inputField.cancelreason,
      headers: { "x-access-token": usertoken }
    }));
    setShowLoading(false)
    setCancelledSlotDate('')
    setCancelledSlotName('')
    setstep1InputField({ cancelreason: "" })
    if (cancelledreservation && cancelledreservation.payload && cancelledreservation.payload.status === 200) {
      toastify({ message: 'Slot Cancelled Successfully' })
      setReservationData(cancelledreservation.payload.userreservation);
      if (cancelledreservation.reservation != null) {
        if (cancelledreservation.reservation.userid != null) {
          if (cancelledreservation.reservation.userid.udid != null) {
            await dispatch(fetchData({
              apiurl: "/reservation/send/notification",
              "messagingtoken": cancelledreservation.reservation.userid.udid,
              "title": "Admin cancelled your reservation",
              "message": cancelledreservation.cart[0].property.title + " : " + step1inputField.cancelreason,
              "propertyname": cancelledreservation.cart[0].property.title,
              headers: { "x-access-token": usertoken }
            }))
          }
        }
      }
    }
  };


  const getReservationDetails = async () => {
    const reservationData = await dispatch(fetchData({
      apiurl: "/reservation/single",
      reservationid: id,
      headers: { "x-access-token": usertoken }
    }));
    if (reservationData && reservationData.payload && reservationData.payload.status === 200) {
      setReservationData(reservationData.payload.data);
    }
  };



  function convertdate(date) {
    return date.substring(6, 8) + "/" + date.substring(9, 11) + "/" + date.substring(1, 5)
  }


  function handlepopupclose() {
    setCancelledSlotDate('')
    setCancelledSlotName('')
  }

  const inputsHandler = (e) => {
    console.log(e.target.name);
    setstep1InputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const handleCancelSlot = async (slotname, slotdate, isSlotAlreadyCancelled) => {
    if (isSlotAlreadyCancelled) {
      toastify({ message: 'This slot is already cancelled' })
      return
    }
    setCancelledSlotDate(slotdate)
    setCancelledSlotName(slotname)
  };

  if (loading) {
    return <div>Loading...</div>; // Show loader while loading
  }

  return (
    <>
      <div className="profile-details">
        <div className="profile-top">
          <h1>Reservation Details</h1>
        </div>

        <div className="profile-details-main">
          {/* Reservation details like hunter name, property name, etc. */}
          <div className="profile-details-content">
            <div className="first-part">
              <p>Hunter Name:</p>
            </div>
            <div className="second-part">
              <p>{reservationdetailsUpdated.userid ? `${reservationdetailsUpdated.userid.firstname} ${reservationdetailsUpdated.userid.lastname}` : ''}</p>
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>Property Name:</p>
            </div>
            <div className="second-part">
              <p>{reservationdetailsUpdated.cart[0]?.property.title}</p>
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>Coupon Applied:</p>
            </div>
            <div className="second-part">
              <p>{reservationdetailsUpdated.couponapplied?.couponcode ? reservationdetailsUpdated.couponapplied.couponcode : reservationdetailsUpdated.userid?.subscriptions?.[0]?.no_of_memberships ? "Membership" : "--"}</p>
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>Coupon Discount:</p>
            </div>
            <div className="second-part">
              <p>{reservationdetailsUpdated.couponapplied?.discountType === 'percentage' ? '' : "$"}{reservationdetailsUpdated.couponapplied?.couponamount || "0"}{reservationdetailsUpdated.couponapplied?.discountType === 'percentage' ? '%' : ""}</p>
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>Reservation ID:</p>
            </div>
            <div className="second-part">
              <p>{reservationdetailsUpdated._id || "--"}</p>
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>Purchased Date:</p>
            </div>
            <div className="second-part">
              <p>{convertdate(JSON.stringify(reservationdetailsUpdated.createdAt))}</p>
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>Reservation Status:</p>
            </div>
            <div className="second-part">
              <tr
                className={
                  reservationdetailsUpdated.orderstatus.toLowerCase() === "cancel"
                    ? "Action_Reservation_Cancel"
                    : reservationdetailsUpdated.orderstatus.toLowerCase() === "pending"
                      ? "Action_Reservation_Pending" : reservationdetailsUpdated.orderstatus.toLowerCase() ===
                        "cancelled"
                        ? "Action_Reservation_Cancelled"
                        : "Action_Reservation_Booked"
                }>
                <td>
                  <button
                    className="reservation-actionbtn"
                  >
                    <i className={reservationdetailsUpdated.orderstatus.toUpperCase() === 'PENDING' ? "fa-regular fa-clock" : reservationdetailsUpdated.orderstatus.toUpperCase() === 'BOOKED' ? "fa-solid fa-circle-check" : "fa-solid fa-circle-xmark"}></i>
                    {reservationdetailsUpdated.orderstatus.toUpperCase()}
                  </button>
                </td>
              </tr>
            </div>
          </div>
        </div>
      </div>

      <div className="resv_section_grid mt-4">
        <div className="add-table Reservation_details_table">
          <table className="table">
            <thead>
              <tr>
                <th>Slot ID</th>
                <th>Selected Date</th>
                <th>Cost</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {reservationdetailsUpdated.cart.map((cart) => {
                let price = cart.selectedslot.price;
                if (!price) {
                  const selectedDate = new Date(cart.selecteddate);
                  const matchingDate = cart.selectedarea.dates.find(dateRange => {
                    const startDate = new Date(dateRange.start);
                    const endDate = new Date(dateRange.end);
                    return selectedDate >= startDate && selectedDate <= endDate;
                  });

                  if (matchingDate) {
                    price = matchingDate.price;
                  }
                }

                return (
                  <tr key={cart._id}>
                    <td>{cart.selectedslot.name}</td>
                    <td>{convertdate(JSON.stringify(cart.selecteddate))}</td>
                    <td>{price ? `$${price}` : 'N/A'}</td>


                    <td
                      className={
                        cart.isSlotCancelled == null
                          ? "Action_Reservation_Cancel"
                          : cart.isSlotCancelled
                            ? "Action_Reservation_Cancelled"
                            : "Action_Reservation_Cancel"
                      }>
                      
                        <button
                          className="reservation-actionbtns"
                          onClick={() => handleCancelSlot(cart.selectedslot.name, cart.selecteddate, cart.isSlotCancelled != null ? cart.isSlotCancelled ? true : false : false)}
                        >
                          <i className={"fa-solid fa-circle-xmark"}></i>
                          {cart.isSlotCancelled != null ? cart.isSlotCancelled ? "CANCELLED" : "CANCEL" : "CANCEL"}
                        </button>
                    

                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {cancelSlotName && cancelSlotDate && (
        <div className="managecou-popup">
          <div className="managecou-content res-delete">
            <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
            <div className="managecou-contentdiv">
              <img src={Xicon} alt="Xicon" />
              <h1>Are you Sure?</h1>
              <p>Are you sure you want to cancel this slot.</p>
              <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  name="cancelreason"
                  onChange={inputsHandler}
                  value={step1inputField.cancelreason}
                ></textarea>
                <label for="floatingTextarea2">Reason...</label>
              </div>
            </div>
            <div className="managecou-btn">
              <button className="btn1" onClick={handlepopupclose}>
                No
              </button>
              <button className="btn2" onClick={cancelTheSlot}>
              {showLoader?<div className="loader"></div>:'Yes'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
