import React, { useState, useEffect } from "react";
import Select from 'react-select';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { fetchData } from '../redux/apiSlice';
import { toastify, userlogout } from "../redux/controller";
import "./style.css";

export default function Createcoupon() {
  const dispatch = useDispatch();
  const search = useLocation().search;
  const couponid = new URLSearchParams(search).get("index");

  const [inputField, setInputField] = useState({
    couponcode: '',
    discountType: 'percentage',
    landowners: [],
    couponamount: 0,
    expiryDate: '',
    usagelimit: 0,
    activereservationcanuse: 0,
    removehandlingcharges: false,
    oneslotperday: false,
    seasonpass: false,
  });

  const [landownersList, setLandownersList] = useState([]);
  const [mindate, setmindate] = useState(new Date().toISOString().split("T")[0]);

  const Navigate = useNavigate();
  const usertoken = localStorage.getItem('usertoken');

  useEffect(() => {
    if (couponid) {
      getcoupon();
    }
    getAllLandowners();
  }, []);

  const getAllLandowners = async (page = 1) => {
    var hunterlist = await dispatch(
      fetchData({
        apiurl: "/auth/user/alllandowners",
        page: page,
        limit: 10000,
        headers: { "x-access-token": usertoken }
      })
    );

    if (hunterlist && hunterlist.payload && hunterlist.payload.status === 200) {
      const landOwnersListLocal = hunterlist.payload.landowners.map((landowner) => ({
        value: landowner.user._id,
        label: landowner.user.firstname + ' ' + landowner.user.lastname
      }));
      // Add "All" option at the beginning
      setLandownersList([{ value: 'all', label: 'All' }, ...landOwnersListLocal]);
    }
  };

  const handleSelectChange = (selectedOptions) => {
    if (selectedOptions.some(option => option.value === 'all')) {
      // Select all landowners if "All" is chosen
      setInputField(prevState => ({
        ...prevState,
        landowners: landownersList.filter(option => option.value !== 'all').map(option => option.value)
      }));
    } else {
      setInputField(prevState => ({
        ...prevState,
        landowners: selectedOptions.map(option => option.value)
      }));
    }
  };

  const inputsHandler = (e) => {
    if (e.target.name === 'couponamount' || e.target.name === 'usagelimit' || e.target.name === 'activereservationcanuse') {
      if (e.target.value < 0 || e.target.value === '-') {
        return toastify({ message: "Value cannot be less than 0" });
      }
    }

    if (e.target.name === 'removehandlingcharges' || e.target.name === 'oneslotperday' || e.target.name === 'seasonpass') {
      setInputField(prevState => ({
        ...prevState,
        [e.target.name]: e.target.value === 'true' || e.target.value === true ? false : true
      }));
    } else if (e.target.name === 'couponamount') {
      if (inputField.couponamount) {
        if (inputField.discountType === 'percentage' && inputField.couponamount > 100) {
          toastify({ message: "Coupon amount cannot be greater than 100%" });
        }
      }
      setInputField(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    } else {
      setInputField(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    }
  };

  async function getcoupon() {
    var couponslist = await dispatch(
      fetchData({
        apiurl: "/coupon/find",
        _id: couponid,
        headers: { "x-access-token": usertoken }
      })
    );

    if (couponslist && couponslist.payload && couponslist.payload.data) {
      setInputField({
        couponcode: couponslist.payload.data.couponcode,
        discountType: couponslist.payload.data.discountType,
        couponamount: couponslist.payload.data.couponamount,
        expiryDate: couponslist.payload.data.expiryDate.substring(0, 10),
        usagelimit: couponslist.payload.data.usagelimit,
        activereservationcanuse: couponslist.payload.data.activereservationcanuse,
        removehandlingcharges: couponslist.payload.data.removehandlingcharges,
        oneslotperday: couponslist.payload.data.oneslotperday,
        seasonpass: couponslist.payload.data.seasonpass,
        landowners: couponslist.payload.data.landowners
      });
    }
  }

  const handleCreate = async () => {

    if (inputField.discountType === 'percentage' && inputField.couponamount > 100) {
      toastify({ message: "Coupon amount cannot be greater than 100%" });
      return;
    }
    if (mindate > inputField.expiryDate) {
      toastify({ message: "Coupon expiry date cannot be lesser than today" });
      return;
    }
    if (inputField.usagelimit < 1) {
      toastify({ message: "Coupon usage limit can't be 0" });
      return;
    }
    if (!inputField.seasonpass) {
      if (inputField.landowners && inputField.landowners.length == 0) {
        toastify({ message: "At least one landowner must be selected" });
        return;
      }
    }
    if (inputField.couponcode && inputField.couponamount) {
      var coupon = await dispatch(fetchData({
        apiurl: "/coupon/create",
        couponcode: inputField.couponcode,
        discountType: inputField.discountType,
        couponamount: inputField.couponamount,
        expiryDate: inputField.expiryDate,
        usagelimit: inputField.usagelimit,
        activereservationcanuse: inputField.activereservationcanuse,
        removehandlingcharges: inputField.removehandlingcharges,
        oneslotperday: inputField.oneslotperday,
        seasonpass: inputField.seasonpass,
        landowners: inputField.landowners,
        headers: { "x-access-token": usertoken }
      }));
      if (coupon && coupon.payload && coupon.payload.status === 200) {
        toastify({ message: coupon.payload.message });
        Navigate("/managecoupons");
      } else {
        if (coupon.payload.response && coupon.payload.response.data && coupon.payload.response.data.message) {
          toastify({ message: coupon.payload.response.data.message });
          if (coupon.payload.response.data.message === "Invalid token provided!") {
            userlogout();
            Navigate('/');
            window.location.reload();
          }
        }
        else if (coupon.payload && coupon.payload.status == 400 && coupon.payload.message) {
          toastify({ message: coupon.payload.message })
        }
      }
    } else {
      toastify({ message: "Please fill all mandatory fields" });
    }
  }

  const handleUpdate = async () => {
    if (!inputField.seasonpass) {
      if (inputField.landowners && inputField.landowners.length == 0) {
        toastify({ message: "At least one landowner must be selected" });
        return;
      }
    }
    if (inputField.discountType === 'percentage' && inputField.couponamount > 100) {
      toastify({ message: "Coupon amount cannot be greater than 100%" });
      return;
    }
    if (inputField.usagelimit < 1) {
      toastify({ message: "Coupon usage limit can't be 0" });
      return;
    }
    if (inputField.couponcode.replaceAll(' ', '') && inputField.couponamount) {
      var coupon = await dispatch(fetchData({
        apiurl: "/coupon/update",
        _id: couponid,
        couponcode: inputField.couponcode,
        discountType: inputField.discountType,
        couponamount: inputField.couponamount,
        expiryDate: inputField.expiryDate,
        usagelimit: inputField.usagelimit,
        activereservationcanuse: inputField.activereservationcanuse,
        removehandlingcharges: inputField.removehandlingcharges,
        oneslotperday: inputField.oneslotperday,
        seasonpass: inputField.seasonpass,
        landowners: inputField.landowners,
        headers: { "x-access-token": usertoken }
      }));
      if (coupon && coupon.payload && coupon.payload.status === 200) {
        toastify({ message: coupon.payload.message });
        Navigate("/managecoupons");
      } else {
        if (coupon.payload.response && coupon.payload.response.data && coupon.payload.response.data.message) {
          toastify({ message: coupon.payload.response.data.message });
          if (coupon.payload.response.data.message === "Invalid token provided!") {
            userlogout();
            Navigate('/');
            window.location.reload();
          }
        }
      }
    } else {
      toastify({ message: "Please fill all mandatory fields" });
    }
  };

  return (
    <div className="add-main">
      <div className="add-formdiv">
        <div className="add-top">
          {couponid ? <h2>Update Coupon</h2> : <h2>Create Coupon</h2>}
        </div>
        <div className="add-form">
          <div className="select-div">
            <div className="select-div1">
              <label htmlFor="exampleInputEmail1" className="form-label required">
                Coupon Code<span className="required-star">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                placeholder="Enter Coupon Code"
                name="couponcode"
                onChange={inputsHandler}
                value={inputField.couponcode}
              />
            </div>
            <div className="select-div2">
              <label htmlFor="exampleInputEmail1" className="form-label required">
                Discount Type
              </label>
              <select
                className="form-select form-select-lg mb-3"
                aria-label="Large select example"
                onChange={inputsHandler}
                name="discountType"
                value={inputField.discountType}
              >
                <option value="percentage">Percentage</option>
                <option value="amount">Amount</option>
              </select>
            </div>
          </div>
          <div className="select-div">
            <div className="select-div1">
              <label htmlFor="exampleInputEmail1" className="form-label required">
                Coupon Amount<span className="required-star">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Coupon Amount"
                name="couponamount"
                onChange={inputsHandler}
                value={inputField.couponamount}
                min={0}
              />
            </div>
            <div className="select-div2">
              <label htmlFor="exampleInputEmail1" className="form-label required">
                Coupon Expiry Date
              </label>
              <input
                type="date"
                onKeyDown={(e) => e.preventDefault()}
                className="form-control"
                placeholder="Enter Coupon Expiry Date"
                name="expiryDate"
                onChange={inputsHandler}
                value={inputField.expiryDate}
                min={mindate}
              />
            </div>
          </div>
          <div className="select-div">
            <div className="select-div2">
              <label htmlFor="exampleInputEmail1" className="form-label required">
                Number of Active Reservations this Coupon Can Be Used
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Number of Active Reservations"
                name="activereservationcanuse"
                onChange={inputsHandler}
                value={inputField.activereservationcanuse}
                min={0}
              />
            </div>
            <div className="select-div1">
              <label htmlFor="exampleInputEmail1" className="form-label required">
                Usage Limit
              </label>
              <input
                type="number"
                className="form-control"
                placeholder="Enter Usage Limit"
                name="usagelimit"
                onChange={inputsHandler}
                value={inputField.usagelimit}
                min={0}
              />
            </div>
          </div>
          <div className="select-div">
            <div className="select-div1">
              <label htmlFor="exampleInputEmail1" className="form-label required">
                Select Landowner {!inputField.seasonpass ? <span className="required-star">*</span> : null}
              </label>
              <Select
                isMulti
                options={landownersList}
                maxMenuHeight={200}
                onChange={handleSelectChange}
                value={landownersList.filter(landowner => inputField.landowners.includes(landowner.value))}
              />
            </div>

            <div className="select-div1">

              <div className="select-div1">
                <label htmlFor="exampleInputEmail1" className="form-label required">
                  Restrict Coupon to 1 Slot Per Day
                </label>
                <div className="createcoupon-checkbox">
                  <input
                    type="checkbox"
                    checked={inputField.oneslotperday}
                    name="oneslotperday"
                    onChange={inputsHandler}
                    value={inputField.oneslotperday}
                  />
                </div>
              </div>
              <div className="select-div1">
                <label
                  htmlFor="exampleInputEmail1"
                  className="form-label required"
                >
                  Remove Handling Charges
                </label>
                <div className="createcoupon-checkbox">
                  <input
                    type="checkbox"
                    checked={inputField.removehandlingcharges}
                    name="removehandlingcharges"
                    onChange={inputsHandler}
                    value={inputField.removehandlingcharges}
                  />
                </div>
              </div>
              <div className="select-div1">
                <label htmlFor="exampleInputEmail1" className="form-label required">
                  Season Pass
                </label>
                <div className="createcoupon-checkbox">
                  <input
                    type="checkbox"
                    checked={inputField.seasonpass}
                    name="seasonpass"
                    onChange={inputsHandler}
                    value={inputField.seasonpass}
                  />
                </div>
              </div>
            </div>


          </div>
          <div className="save-btndiv mt-4" style={{ textAlign: "end" }}>
            {couponid ? (
              <button className="add-btn" onClick={handleUpdate}>Update Coupon</button>
            ) : (
              <button className="add-btn" onClick={handleCreate}>Create Coupon</button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
