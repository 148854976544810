
import React, { useState, useEffect, useContext, useRef } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";

import Badge from '@mui/material/Badge';


import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./sidebar.css";
import {
  logoimg,
  Dashboardicon,
  Huntersicon,
  Landownersiocn,
  Propertiesicon,
  Reservationicon,
  SidebarProfileimg,
  Successicon,
  Logouticon,
  bellIcon,
  couponIcon,
  landowners
} from "../Pages/Images";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { MyContext } from "../App";
import { toastify, userlogout, clearuser } from "../redux/controller";
import { fetchData } from "../redux/apiSlice";

const drawerWidth = 240;

function Sidebar(props) {


  const [userdata, setuserdata] = useState({});
  const usertoken = localStorage.getItem('usertoken');
  const [successPopup, setSuccessPopup] = useState(false);
  const [popstatus, setPopStatus] = useState(false);
  const [logoutpopstatus, setLogoutPopStatus] = useState(false);


  const [hunterCount, setHuntersCount] = useState(0);
  const [landownerCount, setLandownerCount] = useState(0);
  const [propertyCount, setPropertyCount] = useState(0);
  const [reservationCount, setReservationCount] = useState(0);
  const [notifiactionsCount, setNotificationsCount] = useState(0);

  const [isCurrentPasswordVisible, setIsCurrentPasswordVisible] = useState(false);
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

  const currentPasswordRef = useRef(null);
  const newPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);


  const [notifications, setnotifications] = useState([]);


  const isMobile = useContext(MyContext);

  const Navigate = useNavigate();
  const Navigatelogout = useNavigate();

  const handlesuccessPopup = () => {
    setSuccessPopup(!successPopup);
  };
  function handleLogout() {
    Navigatelogout("/");
  }
  function handleLogoutsubmit() {
    setLogoutPopStatus(!logoutpopstatus);
  }
  function handlepopupclose() {
    setLogoutPopStatus(!logoutpopstatus);
  }
  function handleChagepassword() {
    setPopStatus(!popstatus);
  }

  const [stepinputField, setstepInputField] = useState({
    currentpassword: "",
    newpassword: "",
    confirmpassword: ""
  })

  async function handleChagepasswordsubmit() {
    if (stepinputField.currentpassword.length < 1) {
      toastify({ 'message': "Please enter current password" })
      return
    } else if (stepinputField.newpassword.length < 1) {
      toastify({ 'message': "Please enter new password" })
      return
    } else if (stepinputField.newpassword.length < 8) {
      toastify({ "message": "New password must be at least 8 characters" });
      return;
    } else if (stepinputField.newpassword.search(/[a-z]/) < 0) {
      toastify({ "message": "New password must contain at least one lowercase letter" });
      return;
    } else if (stepinputField.newpassword.search(/[A-Z]/) < 0) {
      toastify({ "message": "New password must contain at least one uppercase letter" });
      return;
    } else if (stepinputField.newpassword.search(/[0-9]/) < 0) {
      toastify({ "message": "New password must contain at least one number" });
      return;
    } else if (stepinputField.confirmpassword.length < 1) {
      toastify({ "message": "Please enter confirm password" });
      return;
    } else if (stepinputField.newpassword !== stepinputField.confirmpassword) {
      toastify({ 'message': "Password and confirm password fields do not match. Please ensure both fields are identical." });
      return;
    }
    var body = {
      "apiurl": "/auth/change/password",
      "password": stepinputField.currentpassword,
      "newpassword": stepinputField.newpassword,
      headers: { "x-access-token": usertoken }
    }
    var response = await dispatch(fetchData(body))

    if (response && response.payload && response.payload.status === 200) {
      toastify({ message: response.payload.message })
      setPopStatus(!popstatus);
      setSuccessPopup(true);
    }
    else {
      if (response.payload && response.payload.message) {
        toastify({ message: response.payload.message })
        if (response.payload.message === "Invalid token provided!") {
          userlogout()
          Navigate('/')
        }
      }
    }
  }

  function handleChagepasswordclose() {
    setPopStatus(!popstatus);
  }

  const [currentpage, setcurrentpage] = useState("");

  const location = useLocation();
  const dispatch = useDispatch();

  const getProfile = async () => {
    var profileData = await dispatch(
      fetchData({
        apiurl: "/auth/profile",
        headers: { "x-access-token": usertoken }
      })
    );
    if (profileData && profileData.payload && profileData.payload.status === 200) {
      if (profileData.payload.data && profileData.payload.data.user && profileData.payload.data.user.notifications) {
        if (profileData.payload.data.user.notifications.length > 0) {
          setnotifications(profileData.payload.data.user.notifications.slice().reverse())
          var hCount = 0;
          var lCount = 0;
          var pCount = 0;
          var rCount = 0;
          var totalCount = 0

          for (let index = 0; index < profileData.payload.data.user.notifications.length; index++) {
            const element = profileData.payload.data.user.notifications[index];
            if (element.type === 'hunter') {
              if (!element.read) {
                hCount++
                totalCount++;
              }
            }
            if (element.type === 'landowner') {
              if (!element.read) {
                lCount++
                totalCount++;
              }
            }
            if (element.type === 'property') {
              if (!element.read) {
                pCount++
                totalCount++;
              }
            }
            if (element.type === 'reservation') {
              if (!element.read) {
                rCount++
                totalCount++;
              }
            }
          }

          setHuntersCount(hCount)
          setLandownerCount(lCount)
          setPropertyCount(pCount)
          setReservationCount(rCount)
          setNotificationsCount(totalCount)
        }
      }
    }
    else {
      console.log(profileData.payload.response.data.status)
      console.log(profileData && profileData.payload && profileData.payload.status === 500 && profileData.payload.message == "Invalid token provided!")
      if (profileData && profileData.payload && profileData.payload.response && profileData.payload.response.data && profileData.payload.response.data.status === 500 && profileData.payload.response.data.message == "Invalid token provided!") {
        clearuser()
        userlogout()
        Navigate('/')
        window.location.reload()
      }
    }
  };

  async function updateNotificationCount(type) {
    var data = await dispatch(
      fetchData({
        apiurl: "/auth/user/updatenotificationcount",
        headers: { "x-access-token": usertoken },
        type: type,
        userid: userdata._id
      })
    );
    if (data && data.payload && data.payload.status === 200) {
      if (type == "all") {
        setNotificationsCount(0)
      } else
        if (type === "hunter") {
          setHuntersCount(0)
        } else if (type === "landowner") {
          setLandownerCount(0)
        } else if (type === "property") {
          setPropertyCount(0)
        } else if (type === "reservation") {
          setReservationCount(0)
        }
    }
  }


  useEffect(() => {
    const userinfo = JSON.parse(localStorage.getItem('userdata'));
    if (userinfo) {
      getProfile()
      setuserdata(userinfo);
    }

    setcurrentpage(location.pathname);
  }, [location]);

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const manage_navigate = useNavigate();
  const hunter_navigate = useNavigate();
  const landowners_navigate = useNavigate();
  const reservations_navigate = useNavigate();
  const hunterback_navigate = useNavigate();

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };
  const inputsHandler = (e) => {
    setstepInputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  useEffect(() => {
    if (currentpage === "/dashboard") {
      setActiveMenu("Dashboard");
    } else if (currentpage === "/hunters") {
      setActiveMenu("Hunters");
    } else if (currentpage === "/landowners") {
      setActiveMenu("Landowners ");
    } else if (currentpage === "/properties") {
      setActiveMenu("Properties ");
    } else if (currentpage === "/reservations") {
      setActiveMenu("Reservations");
    }
  }, [currentpage]);

  const handleClick = (text) => {
    setActiveMenu(text === activeMenu ? null : text);
  };


  function handleDashboard() {
    manage_navigate("/dashboard");
  }

  function handleHunters() {
    updateNotificationCount("hunter")
    hunterback_navigate("/hunters");
  }

  function handleHunterback() {
    // hunterback_navigate("/hunters");
    Navigate(-1)
  }


  function handleCreateCouponBack() {
    hunterback_navigate("/managecoupons");
  }

  function handleLandownersback() {
    landowners_navigate("/landowners");
  }

  function handlePropertyback() {
    landowners_navigate("/properties");
  }

  function handleAddHunterBack() {
    landowners_navigate("/hunters");
  }

  function handleReservationBack() {
    Navigate(-1)
  }

  function handleAddLandownerBack() {
    landowners_navigate("/landowners");
  }

  function handleProperties() {
    updateNotificationCount("property")
    hunter_navigate("/properties");
  }
  function handleLandowners() {
    updateNotificationCount("landowner")
    landowners_navigate("/landowners");
  }

  function handleManageCoupon() {
    landowners_navigate("/managecoupons");
  }
  function handleReservations() {
    updateNotificationCount("reservation")
    reservations_navigate("/reservations");
  }

  const handleNewType = () => {
    newPasswordRef.current.type = newPasswordRef.current.type === 'text' ? 'password' : 'text';
    setIsNewPasswordVisible(!isNewPasswordVisible);
  };

  const handleConfirmType = () => {
    confirmPasswordRef.current.type = confirmPasswordRef.current.type === 'text' ? 'password' : 'text';
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  const handleCurrentType = () => {
    currentPasswordRef.current.type = currentPasswordRef.current.type === 'text' ? 'password' : 'text';
    setIsCurrentPasswordVisible(!isCurrentPasswordVisible);
  };

  async function handleDrop() {
    // drop_navigate("/profile");
    updateNotificationCount('all')
  }

  const drawer =
    ((
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    ),
      (
        <div>
          <div className="logo-buttom">
            <img className="logoimg" src={landowners} alt="logoimg" />
          </div>
          <List>
            <React.Fragment>
              <ListItem disablePadding onClick={handleDashboard}>
                <ListItemButton
                  onClick={() => handleClick("Dashboard")}
                  className={
                    activeMenu === "Dashboard" ? "activeMenu" : "inheritmenu"
                  }
                >
                  <ListItemIcon>
                    <img
                      className="cou-img"
                      src={Dashboardicon}
                      alt="Dashboardicon"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Dashboard"
                    className={
                      activeMenu === "Dashboard" ? "activeMenu" : "inheritmenu"
                    }
                  />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          </List>

          <Divider />

          <List>

            <ListItem disablePadding onClick={handleHunters}>
              <ListItemButton
                onClick={() => handleClick("Hunters")}
                className={
                  activeMenu === "Hunters" ? "activeMenu" : "inheritmenu"
                }
              >
                <ListItemIcon>
                  <img className="cou-img" src={Huntersicon} alt="Huntersicon" />
                </ListItemIcon>
                <ListItemText
                  primary="Hunters"
                  className={
                    activeMenu === "Hunters" ? "activeMenu" : "inheritmenu"
                  }
                />
                <Badge badgeContent={hunterCount} sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#be7637',
                    color: 'white',
                    marginRight: '20px'
                  }
                }} />

              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding onClick={handleLandowners}>
              <ListItemButton
                onClick={() => handleClick("Landowners ")}
                className={
                  activeMenu === "Landowners " ? "activeMenu" : "inheritmenu"
                }
              >
                <ListItemIcon>
                  <img
                    className="cou-img"
                    src={Landownersiocn}
                    alt="Landownersiocn"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Landowners "
                  className={
                    activeMenu === "Landowners " ? "activeMenu" : "inheritmenu"
                  }
                />

                <Badge badgeContent={landownerCount} sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#be7637',
                    color: 'white',
                    marginRight: '20px'
                  }
                }} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />




          <List>
            <ListItem disablePadding onClick={handleManageCoupon}>
              <ListItemButton
                onClick={() => handleClick("ManageCoupon")}
                className={
                  activeMenu === "ManageCoupon" ? "activeMenu" : "inheritmenu"
                }
              >
                <ListItemIcon>
                  <img
                    className="cou-img"
                    src={couponIcon}
                    alt="Propertiesicon"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Manage Coupons"
                  className={
                    activeMenu === "ManageCoupon " ? "activeMenu" : "inheritmenu"
                  }
                />
              </ListItemButton>
            </ListItem>
          </List>





          <Divider />
          <List>
            <ListItem disablePadding onClick={handleProperties}>
              <ListItemButton
                onClick={() => handleClick("Properties ")}
                className={
                  activeMenu === "Properties " ? "activeMenu" : "inheritmenu"
                }
              >
                <ListItemIcon>
                  <img
                    className="cou-img"
                    src={Propertiesicon}
                    alt="Propertiesicon"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Properties "
                  className={
                    activeMenu === "Properties " ? "activeMenu" : "inheritmenu"
                  }
                />

                <Badge badgeContent={propertyCount} sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#be7637',
                    color: 'white',
                    marginRight: '20px'
                  }
                }} />
              </ListItemButton>
            </ListItem>
          </List>
          <Divider />
          <List>
            <ListItem disablePadding onClick={handleReservations}>
              <ListItemButton
                onClick={() => handleClick("Reservations")}
                className={
                  activeMenu === "Reservations" ? "activeMenu" : "inheritmenu"
                }
              >
                <ListItemIcon>
                  <img
                    className="cou-img"
                    src={Reservationicon}
                    alt="Reservationicon"
                  />
                </ListItemIcon>
                <ListItemText
                  primary="Reservations"
                  className={
                    activeMenu === "Reservations" ? "activeMenu" : "inheritmenu"
                  }
                />

                <Badge badgeContent={reservationCount} sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: '#be7637',
                    color: 'white',
                    marginRight: '20px'
                  }
                }} />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
      ));

  // Remove this const when copying and pasting into your project.
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` }
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>

            {currentpage === "/profile" ? (
              <div className="header-btndiv">
                <h1>Profile</h1>
              </div>
            ) : null}

            {currentpage === "/managecoupons" ? (
              <div className="header-btndiv">
                <h1>Manage Coupons</h1>
              </div>
            ) : null}

            {currentpage === "/createcoupon" ? (
              <div className="header-icondiv">
                <div className="header-btnicon" onClick={handleCreateCouponBack}>
                  <ArrowBackIcon />
                </div>
                <h1>Create coupon</h1>
              </div>
            ) : null}

            {currentpage === "/editprofile" ? (
              <div className="header-btndiv">
                <h1>Edit Profile</h1>
              </div>
            ) : null}


            {currentpage === "/dashboard" ? (
              <div className="header-btndiv">
                <h1>Dashboard</h1>
              </div>
            ) : null}
            {currentpage === "/hunters" ? (
              <div className="header-btndiv">
                <h1>Hunters</h1>
              </div>
            ) : null}

            {currentpage === "/huntersdetails" ? (
              <div className="header-icondiv">
                <div className="header-btnicon" onClick={handleHunterback}>
                  <ArrowBackIcon />
                </div>
                <h1>Hunters Details</h1>
              </div>
            ) : null}

            {currentpage === "/landowners" ? (
              <div className="header-btndiv">
                <h1>Landowners</h1>
              </div>
            ) : null}

            {currentpage === "/landownersdetails" ? (
              <div className="header-icondiv">
                <div className="header-btnicon" onClick={handleLandownersback}>
                  <ArrowBackIcon />
                </div>
                <h1>Landowner Details</h1>
              </div>
            ) : null}

            {currentpage === "/properties" ? (
              <div className="header-btndiv">
                <h1>Properties</h1>
              </div>
            ) : null}

            {currentpage === "/newhunter" ? (
              <div className="header-icondiv">
                <div className="header-btnicon" onClick={handleAddHunterBack}>
                  <ArrowBackIcon />
                </div>
                <h1>Add Hunter</h1>
              </div>
            ) : null}

            {currentpage.startsWith("/reservationdetails") ? (
              <div className="header-btndiv">
                <div className="header-btnicon" onClick={handleReservationBack}>
                  <ArrowBackIcon />
                </div>
                <h1>Reservation Details</h1>
              </div>
            ) : null}


            {currentpage === "/newlandowner" ? (
              <div className="header-icondiv">
                <div className="header-btnicon" onClick={handleAddLandownerBack}>
                  <ArrowBackIcon />
                </div>
                <h1>Add Landowner</h1>
              </div>
            ) : null}

            {currentpage === "/propertydetails" ? (
              <div className="header-icondiv">
                <div className="header-btnicon" onClick={handlePropertyback}>
                  <ArrowBackIcon />
                </div>
                <h1>Property Details</h1>
              </div>
            ) : null}

            {currentpage === "/reservations" ? (
              <div className="header-btndiv">
                <h1>Reservations</h1>
              </div>
            ) : null}



            <div className="side_top_menu">
              <div
                className="btn-secondary dropdown-toggle top-main"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                onClick={handleDrop}
              >

                <div
                  className="btn-secondary dropdown-toggle belldiv"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={handleDrop}>
                  <img src={bellIcon} alt="Bellicon" style={{ height: "26px", width: "26px" }} />
                  {notifiactionsCount > 0 ? <p className="bell_notification">{notifiactionsCount}</p> : null}

                  <ul className="dropdown-menu bell_drop">
                    <h4 className="notification-title"> Notifications</h4>
                    {notifications.length > 0 ?
                      notifications.map((label, index) => {
                        return (
                          <li>
                            <Link className="dropdown-item" state={label} to={label.type === 'hunter' ? "/hunters" : label.type === 'landowner' ? '/landowners' : label.type === 'property' ? '/propertydetails' : "/reservations"}>
                              {label.msg ? label.msg : "No message in the notification"}
                            </Link>
                          </li>
                        )
                      }

                      ) : <div className="no-notification">
                        <p>No data!</p>
                      </div>}
                  </ul>
                </div>


                <div className="top-image">
                  {userdata.image ? <img className="Profileimg" src={process.env.REACT_APP_BASEURL + "/" + userdata.image} alt="Profileimg" /> : <img className="Profileimg" src={SidebarProfileimg} alt="Profileimg" />}
                </div>



                {!isMobile ? (
                  <>
                    <div className="top-midtext">
                      <p>{userdata.email} </p>
                      <p>{userdata.firstname} {userdata.lastname}</p>
                    </div>

                    {/* <div className="top-midtext">
                <p>{userdata.email}</p>
                <p>
                  {userdata.firstname} {userdata.lastname}
                </p>
              </div> */}
                    <div className="top-icon">
                      <KeyboardArrowDownIcon />
                    </div>
                  </>
                ) : null}
              </div>

              <ul className="dropdown-menu">
                {isMobile ? (
                  <li>
                    <div className="user-drop">
                      <div className="">
                        <img
                          className="Profileimg"
                          src={SidebarProfileimg}
                          alt="Profileimg"
                        />
                      </div>
                      <div className="top-midtext">
                        <p>{userdata.email} </p>
                        <p>{userdata.firstname} {userdata.lastname}</p>
                      </div>
                    </div>
                  </li>
                ) : null}
                <li>
                  <Link className="dropdown-item" to="/profile">
                    My Profile
                  </Link>
                </li>
                <li>
                  <Link
                    className="dropdown-item"
                    //  to="/change_pass?popstatus=true"
                    onClick={handleChagepassword}
                  >
                    Change Password
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" onClick={handleLogoutsubmit}>
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
            {/* <div style={{ flexGrow: 1 }}></div> */}
            {/* <Badge badgeContent='2' sx={{
              '& .MuiBadge-badge': {
                backgroundColor: '#be7637',
                color: 'white',
                marginRight: '20px'
              }
            }} >
              <ListItemIcon>
                <img
                  className="cou-img"
                  src={bellIcon}
                  alt="Reservationicon"
                />
              </ListItemIcon>
            </Badge> */}

          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{
              keepMounted: true
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth
              }
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: "none", sm: "block" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth
              }
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` }
          }}
        >
          <Toolbar />
          {props.children}
        </Box>
      </Box>

      {popstatus && (
        <div className="chagepassword-popup">
          <div className="chagepassword-content">
            <i
              className="fa-solid fa-xmark"
              onClick={handleChagepasswordclose}
            ></i>

            <div className="chagepass-popupcontent">
              <h1>Change Password</h1>
              <p>To change your password, please fill the fields below.</p>

              <div className="form-row">
                <div className="input-group">
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="Current Password"
                    name="currentpassword"
                    onChange={inputsHandler}
                    value={stepinputField.currentpassword}
                    ref={currentPasswordRef}
                  />
                  <button
                    type="button"
                    className="eye-icon btn btn-outline-secondary"
                    onClick={handleCurrentType}
                  >
                    <i className={`fa ${isCurrentPasswordVisible ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                  </button>
                </div>
                <div className="input-group">
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="New Password"
                    name="newpassword"
                    onChange={inputsHandler}
                    value={stepinputField.newpassword}
                    ref={newPasswordRef}
                  />
                  <button
                    type="button"
                    className="eye-icon btn btn-outline-secondary"
                    onClick={handleNewType}
                  >
                    <i className={`fa ${isNewPasswordVisible ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                  </button>
                </div>
                <div className="input-group">
                  <input
                    type="password"
                    id="password"
                    className="form-control"
                    placeholder="Confirm New Password"
                    name="confirmpassword"
                    onChange={inputsHandler}
                    value={stepinputField.confirmpassword}
                    ref={confirmPasswordRef}
                  />
                  <button
                    type="button"
                    className="eye-icon btn btn-outline-secondary"
                    onClick={handleConfirmType}
                  >
                    <i className={`fa ${isConfirmPasswordVisible ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                  </button>
                </div>
              </div>
              <div className="form-row w-100">
                <button
                  type="submit"
                  className="users_btn change-passbtn"
                  onClick={handleChagepasswordsubmit}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {successPopup && (
        <div className="chagesuccess-popup">
          <div className="chagesuccess-content">
            <i className="fa-solid fa-xmark" onClick={handlesuccessPopup}></i>
            <div className="chagesuccess-contentdiv">
              <img src={Successicon} alt="Successicon" />
              <h1>Password Change</h1>
              <h1> Successfully </h1>
            </div>
          </div>
        </div>
      )}

      {logoutpopstatus && (
        <div className="logout-popup">
          <div className="logout-content">
            <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
            <div className="logout-contentdiv">
              <img src={Logouticon} alt="Logouticon" />
              <h1>Are you Sure?</h1>
              <p>Are you absolutely sure you want to Logout?</p>
            </div>
            <div className="logout-btn">
              <button className="btn1" onClick={handlepopupclose}>
                Cancel
              </button>
              <button className="btn2" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

Sidebar.propTypes = {
  window: PropTypes.func,
  children: PropTypes.node
};

export default Sidebar;