import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Xicon, approveIcon } from "./Images"
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout } from "../redux/controller";
import YouTubePlayer from '../Components/YoutubePlayer';


export default function PropertyDetails() {


  const approve_navigate = useNavigate();

  const [rejectstatus, setRejectstatus] = useState(false);
  const [approveStatus, setApproveStatus] = useState(false);
  const [emptyState, setEmptyState] = useState(false);
  const navigate = useNavigate();

  const [propertyG, setPropertyData] = useState();

  const [propertyId, setPropertyId] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const [propertyCategory, setPropertyCategory] = useState('');
  const [propertyState, setPropertyState] = useState('');
  const [propertyCounty, setPropertyCounty] = useState('');
  const [propertyCity, setPropertyCity] = useState('');
  const [propertyZipCode, setPropertyZipCode] = useState('');
  const [propertyTotalArea, setPropertyTotalArea] = useState('');
  const [propertyAction, setPropertyAction] = useState('');
  const [propertyLandownerId, setPropertyLandownerId] = useState('');


  const [step1inputField, setstep1InputField] = useState({
    cancelreason: ""
  })


  const location = useLocation();
  const { state } = location;
  const { selectedid, id, property_name, category, stateLocal, county, city, zipcode, totalarea, property, action, landownerId } = state || {};

  const [status, setStatus] = useState('Loading...');


  useEffect(() => {
    if (property) {
      setPropertyData(property)
      setPropertyId(id)
      setPropertyName(property_name)
      setPropertyCategory(category)
      setPropertyState(stateLocal)
      setPropertyCounty(county)
      setPropertyCity(city)
      setPropertyZipCode(zipcode)
      setPropertyTotalArea(totalarea)
      setPropertyAction(action)
      setPropertyLandownerId(landownerId)

      if (property.area) {
        for (let i = 0; i < property.area.length; i++) {
          property.area[i]['isAreaUnit'] = 'unit';
        }
        setEmptyState(!emptyState)
      }
    } else {
      if (selectedid) {
        setPropertyId(selectedid)
        getPropertyDetails()
      }else{
        navigate('/')
      }
    }
  }, []);

  const getPropertyDetails = async () => {
    setStatus('Loading...')
    const property = await dispatch(
      fetchData({
        apiurl: "/property/listAllproperty",
        propertyid: selectedid ?? propertyId,
        headers: { "x-access-token": usertoken }
      })
    );


    if (property && property.payload && property.payload.status === 200) {
      setStatus('')
      var propertLocal = property.payload.data.property;

      if (propertLocal.area) {
        const updatedArea = propertLocal.area.map(item => {
          return {
            ...item,
            isAreaUnit: 'unit'
          };
        });
        propertLocal = {
          ...propertLocal,
          area: updatedArea
        };
      }

      setPropertyData(propertLocal)

      setPropertyId(property.payload.data.property._id)
      setPropertyName(property.payload.data.property.title)
      setPropertyCategory(property.payload.data.property.category)

      setPropertyState(property.payload.data.property.state)
      setPropertyCounty(property.payload.data.property.county)
      setPropertyCity(property.payload.data.property.city)
      setPropertyZipCode(property.payload.data.property.zipcode)
      setPropertyTotalArea(property.payload.data.property.totalarea)
      setPropertyAction(property.payload.data.property.approved == null ? 'Pending' : property.payload.data.property.approved === "false" ? 'Rejected' : 'Approved',)
      setPropertyLandownerId(property.payload.data.landownername._id)



    } else {
      if (property.payload.response && property.payload.response.data && property.payload.response.data.message) {
        if (property.payload.response.data.message === "Invalid token provided!") {
          toastify({ message: 'Session expired' });
          userlogout();
          navigate('/');
        }
      }
    }


  }

  function setActiveTab(index, name) {
    propertyG.area[index]['isAreaUnit'] = name;
    setEmptyState(!emptyState)
  }

  function handlepopupclose() {
    setRejectstatus(false);
    setApproveStatus(false);
  }

  function handleReject() {
    setRejectstatus(true);
  }


  function handleApprove() {
    setApproveStatus(true);
  }

  function convertdate(date){
    return date.substring(5, 7)+"/"+date.substring(8, 10)+"/"+date.substring(0, 4)
  }

  const dispatch = useDispatch();
  const usertoken = localStorage.getItem('usertoken');

  const handleApproveProperty = async () => {
    var response = await dispatch(
      fetchData({
        apiurl: "/property/approverejectproperty",
        page: 1,
        limit: 100,
        _id: propertyId,
        status: "true",
        propertyName: propertyName,
        selectedid: propertyId,
        landownerId: propertyLandownerId,
        headers: { "x-access-token": usertoken }
      })
    );

    setApproveStatus(false);
    if (response.payload.status === 200) {
      toastify({ "message": 'Property approved successfully' })
      approve_navigate("/properties");
    } else {
      toastify({ message: response.payload.message });
    }

  };

  const handleRejectProperty = async () => {
    var response = await dispatch(
      fetchData({
        apiurl: "/property/approverejectproperty",
        page: 1,
        limit: 100,
        _id: propertyId,
        status: "false",
        landownerId: propertyLandownerId,
        propertyName: propertyName,
        reason: step1inputField.cancelreason,
        selectedid: propertyId,
        headers: { "x-access-token": usertoken }
      })
    );
    setRejectstatus(false);
    if (response.payload.status === 200) {
      toastify({ "message": 'Property rejected successfully' })
      approve_navigate("/properties");
    } else {
      toastify({ message: response.payload.message });
    }
  };


  // Utility function to extract video ID from a YouTube URL
  const getYouTubeVideoId = (url) => {
    try {
      const urlObj = new URL(url);
      return urlObj.searchParams.get('v');
    } catch (e) {
      return null
    }
  };


  // function handlePopupreject() {
  //   approve_navigate("/properties");
  // }
  // function handleApprove() {
  //   approve_navigate("/properties");
  // }
  const videoId = propertyG && propertyG.videolink == null ? null : propertyG && propertyG.videolink ? getYouTubeVideoId(propertyG.videolink) : null;
  const inputsHandler = (e) => {
    console.log(e.target.name);
    setstep1InputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  return (propertyG ?
    <div className="pro-detailsmain">
      <div className="add-formdiv-pro Property details_inner_section">
        <div className="add-main">
          <div className="add-formdiv">
            <div className="add-top">
              <div className="slot-datetable">
                <div className="slot-datetable1">
                  <h2>{propertyName}</h2>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="add-form">
          <div className="select-div">
            <div className="select-div1">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Property Name:
              </label>
              <input
                type="text"
                readOnly="true"
                value={propertyName}
                className="form-control"
                placeholder="Enter Property Name"
              />
            </div>
            <div className="select-div2">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Categary:
              </label>
              <input
                type="text"
                className="form-control"
                readOnly="true"
                value={propertyCategory}
                placeholder="Enter Categary"
              />
            </div>
          </div>

          <div className="select-div">
            <div className="select-div1">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Google Address:
              </label>
              <input
                type="text"
                className="form-control"
                readOnly="true"
                value={propertyG.areaaddress}
                placeholder="Enter Google Address"
              />
            </div>
            <div className="select-div2">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Country:
              </label>
              <input
                type="text"
                readOnly="true"
                value={propertyCounty}
                className="form-control"
                placeholder="Enter Country Name"
              />
            </div>
          </div>

          <div className="select-div">
            <div className="select-div1">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                City:
              </label>
              <input
                type="text"
                readOnly="true"
                value={propertyCity}
                className="form-control"
                placeholder="Enter City Name"
              />
            </div>
            <div className="select-div2">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                State:
              </label>
              <input
                type="text"
                className="form-control"
                readOnly="true"
                value={propertyState}
                placeholder="Enter State Name"
              />
            </div>
          </div>

          <div className="select-div">
            <div className="select-div1">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                ZipCode:
              </label>
              <input
                type="text"
                className="form-control"
                readOnly="true"
                value={propertyZipCode}
                placeholder="Enter ZipCode"
              />
            </div>
            <div className="select-div2">
              <label
                htmlFor="exampleInputEmail1"
                className="form-label required"
              >
                Total Property Area in Acres:
              </label>
              <input
                type="text"
                className="form-control"
                readOnly="true"
                value={propertyTotalArea}
                placeholder="Enter Total Property Area"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="pro-review-top">
        <div class="pro-review-ftop">
          <h1>Areas &amp; Slots</h1>
        </div>
      </div>


      {
        propertyG.area.map((area, index) => (
          <div className="add-formdiv mb-4">
            <div className="slot-datetable">
              <div className="slot-datetable1 add-top">
                <h2>{area.areaname}</h2>
              </div>
            </div>

            <div key={index} className="add-form">
              <div className="areas&slot-div">
                <div className="areas&slot-buttion">
                  <button
                    className={area.isAreaUnit === `unit` ? "button1" : "button2"}
                    onClick={() => setActiveTab(index, 'unit')}
                  >
                    Slots
                  </button>
                  <button
                    className={area.isAreaUnit === `availability` ? "button1" : "button2"}
                    onClick={() => setActiveTab(index, 'availability')}
                  >
                    Availability
                  </button>
                </div>
                {area.isAreaUnit === 'unit' && (
                  <div className="add-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Slot</th>
                          <th scope="col">Size in Acres</th>
                        </tr>
                      </thead>
                      <tbody>
                        {area.slots.map((slot, slotIndex) => (
                          <tr key={slotIndex}>
                            <td>{slot.name}</td>
                            <td>{slot.size} {slot.unit}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {area.isAreaUnit === 'availability' && (
                  <div className="add-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th scope="col">Start Date</th>
                          <th scope="col">End Date</th>
                          <th scope="col">Price($)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {area.dates.map((date, dateIndex) => (
                          <tr key={dateIndex}>
                            <td>
                              {/* {new Date(date.start).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            })} */}
                            {convertdate(date.start)}
                            </td>
                            <td>
                              {/* {new Date(date.end).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: '2-digit',
                              day: '2-digit',
                            })} */}
                            
                            {convertdate(date.end)}
                            </td>
                            <td>{date.price}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>

          </div>
        ))}




      {
        videoId || (propertyG && propertyG.images && propertyG.images.length > 0) ?
          <div className="add-formdiv-media">
            <div className="slot-datetable">
              <div className="slot-datetable1 add-top">
                <h2>Property Media</h2>
              </div>
            </div>
            <div className="pro-imgdiv">
              {videoId ?
                <YouTubePlayer videoId={videoId} />
                : <p></p>
              }

              {propertyG.images.map((image, index) => (
                <div className="pro-imgdiv1">
                  <img src={process.env.REACT_APP_PROPERTY_IMAGE_BASEURL + '/' + image} alt="image1" />
                </div>
              ))}

            </div>
          </div>
          : <p></p>
      }

      <div className="pro-btndiv">
        <button
          className="Reject"
          onClick={handleReject}
          disabled={propertyAction === 'Rejected'}>
          {propertyAction === 'Pending' ? 'Reject' : propertyAction === 'Rejected' ? 'Rejected' : 'Reject'}
        </button>

        <button
          className="Approve"
          disabled={propertyAction === 'Approved'}
          onClick={handleApprove}>
          {propertyAction === 'Pending' ? 'Approve' : propertyAction === 'Rejected' ? 'Approve' : 'Approved'}
        </button>

      </div>

      {rejectstatus && (
        <div className="managecou-popup">
          <div className="managecou-reject">
            <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
            <div className="managecou-contentdiv">
              <img src={Xicon} alt="Xicon" />
              <h1>Are you Sure?</h1>
              <p>Are you sure you want to reject this property?</p>
              <textarea
                className="reject-reason"
                placeholder="Enter reason"
                id="floatingTextarea2"
                name="cancelreason"
                onChange={inputsHandler}
                value={step1inputField.cancelreason}
              ></textarea>
            </div>
            <div className="managecou-btn">
              <button className="btn1" onClick={handlepopupclose}>
                Cancel
              </button>
              <button className="btn2" onClick={handleRejectProperty}>
                Reject
              </button>
            </div>
          </div>
        </div>
      )}

      {approveStatus && (
        <div className="managecou-popup">
          <div className="managecou-content">
            <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
            <div className="managecou-contentdiv">
              <img src={approveIcon} alt="approveIcon" />
              <h1>Are you Sure?</h1>
              <p>Are you sure you want to approve this property?</p>
            </div>
            <div className="managecou-btn">
              <button className="btn1" onClick={handlepopupclose}>
                Cancel
              </button>
              <button className="btnApprove2" onClick={handleApproveProperty}>
                Approve
              </button>
            </div>
          </div>
        </div>
      )}
    </div> : null
  );
}
