import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Xicon from "../assets/Delete Icon.svg";
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout } from '../redux/controller';

export default function ManageCoupons() {

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(0); // Page index starts from 0
  const [selectedCoupon, setSelectedCoupon] = useState('');
  const [searchInput, setSearchInput] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [totalcoupons, settotalCoupons] = useState(0);
  const [manageCouponStatus, setManageCouponStatus] = useState(false);
  const Navigate = useNavigate();
  const perPage = 10;
  const userToken = useSelector((state) => state.userdata.usertoken);


  useEffect(() => {
    getCoupons(searchInput);
  }, [currentPage]);

  function convertdate(date) {
    return date.substring(5, 7) + "/" + date.substring(8, 10) + "/" + date.substring(0, 4)
  }

  async function getCoupons(search) {
    if (userToken == null || userToken.length < 1) {
      userlogout()
      Navigate('/')
      window.location.reload()
      return
    }
    const response = await dispatch(
      fetchData({
        apiurl: "/coupon/find",
        page: currentPage + 1,
        limit: perPage,
        search: search,
        headers: { "x-access-token": userToken }
      })
    );
    if (response && response.payload && response.payload.data && response.payload.status == 200) {
      setCoupons(response.payload.data);
      settotalCoupons(response.payload.count)
      if (response.payload.count > 0 && response.payload.data.length == 0) {
        setCurrentPage(currentPage - 1)
      }
    } else {
      setCoupons([]);
      if (response.payload.response && response.payload.response.data && response.payload.response.data.message) {
        toastify({ message: response.payload.response.data.message })
        if (response.payload.response.data.message == "Invalid token provided!") {
          userlogout()
          Navigate('/')
          window.location.reload()
        }
      }
    }
  }

  async function handlePopupCloseDelete() {
    const response = await dispatch(fetchData({
      apiurl: "/coupon/delete",
      _id: selectedCoupon,
      headers: { "x-access-token": userToken }
    }));
    if (response && response.payload && response.payload.status === 200) {
      toastify({ message: response.payload.message });
      setManageCouponStatus(false);
      getCoupons(searchInput);
    }
    else {
      if (response.payload.response && response.payload.response.data && response.payload.response.data.message) {
        toastify({ message: response.payload.response.data.message })
        if (response.payload.response.data.message == "Invalid token provided!") {
          userlogout()
          Navigate('/')
          window.location.reload()
        }
      }
    }
  }


  function handleDeleteClick(e) {
    const id = e.target.id.split("_")[1];
    if (id) {
      setSelectedCoupon(id);
      setManageCouponStatus(true);
    }
  }

  function handleEdit(e) {
    const id = e.target.id.split("_")[1];
    if (id) {
      Navigate(`/createcoupon?index=${id}`);
    }
  }

  function handleNewCoupon() {
    Navigate("/createcoupon");
  }

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const pageCount = Math.ceil(coupons.length / perPage);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      getCoupons(searchInput);
    }
  };

  const handleClearSearch = () => {
    setSearchInput("")
    getCoupons("")
  };

  const searchForCoupon = () => {
    getCoupons(searchInput)
  }

  return (
    <div>
      <div className="header-btndiv1">
        <div className="search-div">
          <div className="coupon-wrapper">
            <input
              type="text"
              className="form-control hunter-search"
              placeholder="Coupon code"
              value={searchInput}
              onKeyDown={handleKeyDown}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            {searchInput && (
              <button className="clear-btn" onClick={handleClearSearch}>×</button>
            )}
          </div>
          <button className="coupon-search" onClick={searchForCoupon}>Search</button>
        </div>
        <button onClick={handleNewCoupon}>
          <AddIcon /> Add New Coupon
        </button>
      </div>
      <div className="add-form managecoupons-table">
        <div className="add-table manage_coupon_table">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Coupon Code</th>
                <th scope="col">Discount Type</th>
                <th scope="col">Coupon Amount</th>
                <th scope="col">Coupon Expire Date</th>
                <th scope="col">Usage Count</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody style={{ display: coupons && coupons.length ? 'contents' : 'table-caption' }}>
              {coupons.length ? coupons.map((coupon, index) => (
                <tr key={index}>
                  <td>{coupon.couponcode}</td>
                  <td>{coupon.discountType}</td>
                  <td>{coupon.discountType == 'percentage' ? '' : '$'}{coupon.couponamount}{coupon.discountType == 'percentage' ? '%' : ''}</td>
                  <td>
                    {/* {new Date(coupon.expiryDate).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })} */}
                    {convertdate(coupon.expiryDate)}
                  </td>
                  {/* <td>{coupon.expiryDate.substring(0, 10)}</td> */}
                  <td>{coupon.usagelimit}</td>
                  <td>
                    <span className="action-icons">
                      <div className="manage-editdiv">
                        <button id={"coupon_" + coupon._id} className="manage-editbtn" onClick={handleEdit}>
                          <i className="fa-solid fa-pen" style={{ pointerEvents: 'none' }}></i>Edit
                        </button>
                      </div>
                      <div className="manage-deletediv" onClick={handleDeleteClick}>
                        <button className="manage-deletebtn" id={"deletecoupon_" + coupon._id}>
                          <i className="fas fa-trash-alt" style={{ pointerEvents: 'none' }}></i>Delete
                        </button>
                      </div>
                    </span>
                  </td>
                </tr>
              )) : <p className="header-empty">No Coupons found</p>}
            </tbody>
          </table>
        </div>
        {coupons.length ? <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={totalcoupons / perPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={10}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          activeClassName={"active"}
        /> : null}
      </div>

      {manageCouponStatus && (
        <div className="managecou-popup">
          <div className="managecou-content">
            <i className="fa-solid fa-xmark" onClick={() => setManageCouponStatus(false)}></i>
            <div className="managecou-contentdiv">
              <img src={Xicon} alt="Xicon" />
              <h1>Are you Sure?</h1>
              <p>
                Are you sure you want to delete this coupon? Please note that
                this action cannot be undone.
              </p>
            </div>
            <div className="managecou-btn">
              <button className="btn1" onClick={() => setManageCouponStatus(false)}>
                Cancel
              </button>
              <button className="btn2" onClick={handlePopupCloseDelete}>
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
