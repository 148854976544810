import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Sidebar from "./Sidebar/Sidebar";
import Login from "./Components/Login";
import Dashboard from "./Pages/Dashboard";
import Profile from "./Components/Profile";
import Editprofile from "./Components/Editprofile";
import Hunters from "./Pages/Hunters";
import HuntersDetails from "./Pages/HuntersDetails";
import Landowners from "./Pages/Landowners";
import LandownersDetails from "./Pages/LandownersDetails";
import Properties from "./Pages/Properties";
import PropertyDetails from "./Pages/PropertyDetails";
import Reservations from "./Pages/Reservations";
import Managecoupons from "./Pages/Managecoupons";
import NewLandowner from "./Pages/create_new_landowner";
import NewHunter from "./Pages/create_new_hunter";
import "./Components/App.css";
import Createcoupon from "./Pages/Createcoupon";
import "./Pages/style.css";
import ReservationDetails from "./Pages/ReservationDetails";
import { ToastContainer } from "react-toastify";
import PropertyDetailsMap from "./Pages/PropertyDetailsMap";

export const MyContext = createContext();

function App() {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia("(max-width: 767px)").matches
  );




  useEffect(() => {

    const updateWidthAndHeight = () => {
      setIsMobile(window.matchMedia("(max-width: 767px)").matches);
    };

    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  }, []);
  // console.log(isMobile,"isMobile");

  return (
    <MyContext.Provider value={isMobile}>
      <ToastContainer
        position="top-right"
        hideProgressBar={true}
        newestOnTop={true}
        autoClose={5000}
      />
      <Router>
        <Routes>
          <Route
            exact
            path="/dashboard"
            element={
              <Sidebar>
                <Dashboard />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/profile"
            element={
              <Sidebar>
                <Profile />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/editprofile"
            element={
              <Sidebar>
                <Editprofile />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/hunters"
            element={
              <Sidebar>
                <Hunters />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/huntersdetails"
            element={
              <Sidebar>
                <HuntersDetails />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/landowners"
            element={
              <Sidebar>
                <Landowners />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/landownersdetails"
            element={
              <Sidebar>
                <LandownersDetails />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/properties"
            element={
              <Sidebar>
                <Properties />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/propertydetails"
            element={
              <Sidebar>
                <PropertyDetails />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/propertydetailsmap"
            element={
              <Sidebar>
                <PropertyDetailsMap />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/createcoupon"
            element={
              <Sidebar>
                <Createcoupon />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/reservations"
            element={
              <Sidebar>
                <Reservations />
              </Sidebar>
            }
          />
          <Route
            exact
            path="/managecoupons"
            element={
              <Sidebar>
                <Managecoupons />
              </Sidebar>
            }
          />

          <Route
            exact
            path="/newlandowner"
            element={
              <Sidebar>
                <NewLandowner />
              </Sidebar>
            }
          />

          <Route
            exact
            path="/newhunter"
            element={
              <Sidebar>
                <NewHunter />
              </Sidebar>
            }
          />

          <Route
            exact
            path="/reservationdetails/:id"
            element={
              <Sidebar>
                <ReservationDetails />
              </Sidebar>
            }
          />

          {/* Routes without Sidebar */}
          <Route exact path="/" element={<Login />} />
        </Routes>
      </Router>
    </MyContext.Provider>
  );
}

export default App;
