import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

import React, { useState, useEffect } from "react";
import "./style.css";
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout } from '../redux/controller';
import 'react-toastify/dist/ReactToastify.css';

export default function Landowners() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [Landowenerdata, setReservationsdata] = useState([]);
  const [status, setStatus] = useState('Loading...');
  const [searchInput, setSearchInput] = useState("");

  const usertoken = localStorage.getItem('usertoken');

  const [totalReservations, setTotalReservations] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);


  const [perpage, setPerPage] = useState(10);

  const inputperPage = (event) => {
    setCurrentPage(1);
    setPerPage(event.target.value);
  };

  const getAllHunters = async (searchQuery = "", page = 1) => {
    setReservationsdata([]);
    setStatus('Loading...')
    var hunterlist = await dispatch(
      fetchData({
        apiurl: "/auth/user/alllandowners",
        page: page,
        limit: perpage,
        search: searchQuery,
        headers: { "x-access-token": usertoken }
      })
    );

    if (hunterlist && hunterlist.payload && hunterlist.payload.status === 200) {

      if (hunterlist.payload.totalLandowners === 0) {
        setStatus('No Landowner!');
        setCurrentPage(1)
        setTotalReservations(0)
      } else {
        const huntersListLocal = hunterlist.payload.landowners.map((landowners) => ({

          id: landowners.user._id,
          Landowner: landowners.user.firstname + ' ' + landowners.user.lastname,
          email: landowners.user.email ?? '--',
          phone_number: landowners.user.phonenumber ?? '--',
          createdAt: landowners.user.createdAt ?? '--',
          address: landowners.user.livein ?? '--',
          property_listed: landowners.properties,
          action: landowners.user.stripeaccountdata != null ? 'Completed' : 'Pending'
        }));



        setReservationsdata(huntersListLocal);


        setTotalReservations(hunterlist.payload.totalLandowners);
        setStatus('');
      }
    } else {
      if (hunterlist.payload.response && hunterlist.payload.response.message) {
        if (hunterlist.payload.response.message === "Invalid token provided!") {
          toastify({ message: 'Session expired' });
          userlogout();
          navigate('/');
        }
      }
    }
  };

  useEffect(() => {
    getAllHunters(searchInput, currentPage);
  }, [currentPage, perpage]);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleSearch = () => {
    setReservationsdata([]);
    setStatus('Loading...')
    getAllHunters(searchInput, 1);
    setCurrentPage(1);
  };

  const handleClearSearch = () => {
    setReservationsdata([]);
    setStatus('Loading...')
    setSearchInput("")
    getAllHunters("", 1)
    setCurrentPage(1)
    setTotalReservations(0)
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  function handleAddLandowner() {
    navigate("/newlandowner")
  };


  return (
    <>
      <div className="landowner-main">
        <div className="search-div">
          <button className="add-btn-hunter" onClick={handleAddLandowner}>+ Add Landowner</button>
          <div className="input-wrapper">
            <input
              type="text"
              className="form-control hunter-search"
              placeholder="Landowner Name, Email"
              value={searchInput}
              onKeyDown={handleKeyDown}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            {searchInput && (
              <button className="clear-btn" onClick={handleClearSearch}>×</button>
            )}
          </div>
          <button className="add-btn" onClick={handleSearch}>Search</button>
        </div>

        <div className="managecoupons-table add-form landowner-main">
          <div className="add-table landowner_table">
            <table className="table">
              <thead>
                <tr>
                  <th>Landowner Name</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                  <th>Address</th>
                  <th>No. of property listed</th>
                  <th>Payment Account Status</th>
                </tr>
              </thead>
              <tbody>
                {Landowenerdata.length === 0 ?
                  <td colSpan="6" style={{ textAlign: 'center' }}>
                    <div style={{
                      paddingLeft: '10px',
                      paddingTop: '40px',
                      display: 'inline-block',
                    }}>
                      {status}
                    </div>
                  </td>
                  :
                  Landowenerdata.map((reservation,index) => (
                    <tr
                      key={reservation.id}
                      className={
                        reservation.action === "Pending"
                          ? "Action_Pending"
                          : reservation.action === "Completed"
                            ? "Action_Connected"
                            : ""
                      }
                    >
                      <td>{reservation.Landowner}</td>
                      <td>{reservation.email}</td>
                      <td>{reservation.phone_number}</td>
                      <td>{reservation.address}</td>
                      <td><Link className="hunter-link" to={`/landownersdetails`} state={reservation}>{reservation.property_listed}</Link></td>
                      <td>
                        <button
                          className="reservation-actionbtn"
                          onClick={() =>
                            reservation.action.toLowerCase() === "Pending"
                          }
                        >
                          <i class={
                            reservation.action === "Completed"
                              ? "fa-solid fa-circle-check"
                              :
                              "fa-regular fa-clock"
                          }></i>
                          {reservation.action == "Completed" ? "Ready to accept payments" : reservation.action}
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="rowperpage">
            <div className="perpagecount">
              Row Per Page
              <select
                onChange={inputperPage}
                value={perpage}>
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={150}>150</option>
                <option value={200}>200</option>
              </select>
            </div>
            <ReactPaginate
              pageCount={Math.ceil(totalReservations / perpage)}
              pageRangeDisplayed={perpage}
              breakLabel={"..."}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>


        </div>
      </div>
    </>
  );
}
