import { createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


var initialState = {
  usertoken: "",
  userdata: {},
}
console.log("localStorage.getItem('token')")
console.log(localStorage.getItem('usertoken'))
if (localStorage.getItem('usertoken')) {
  initialState.usertoken = localStorage.getItem('usertoken')
  initialState.userdata = localStorage.getItem('userdata')
}
export const userdataSlice = createSlice({
  name: 'userdata',
  initialState,
  reducers: {
    userlogin: (state, params) => {
      state.usertoken = params.payload.token
      state.userdata = params.payload.user
      localStorage.setItem("userdata", JSON.stringify(params.payload.user))
      localStorage.setItem("usertoken", params.payload.token)
    },
    userlogout: (state) => {
      state.usertoken = ""
      state.userdata = {}
    }
  },
})

export const clearuser = async (message) => {
  localStorage.removeItem("userdata")
  localStorage.removeItem("usertoken")
  const userChecked = localStorage.getItem('userChecked');
  if (userChecked === 'false') {
    localStorage.removeItem("userEmail")
    localStorage.removeItem("userPassword")
  }
};

export const toastify = async (message) => {
  toast.info(message.message, {
    autoClose: 5000,
    icon: false,
  });
};

// Action creators are generated for each case reducer function
export const { userlogin, userlogout } = userdataSlice.actions

export default userdataSlice.reducer