import React, { useState } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { fetchData } from '../redux/apiSlice';
import { toastify } from "../redux/controller";

const NewLandowner = () => {
  let Navigate = useNavigate();
  const dispatch = useDispatch()
  const [passwordicon, setpasswordicon] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleType = (e) => {
    if (e.target.parentElement.parentNode.getElementsByTagName('input')[0].type == 'text') {
      e.target.parentElement.parentNode.getElementsByTagName('input')[0].type = 'password'
      setpasswordicon(false)
    }
    else {
      e.target.parentElement.parentNode.getElementsByTagName('input')[0].type = 'text'
      setpasswordicon(true)
    }
  }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const signupclick = async () => {
    if (!loading) {
      if (inputField.first_name.length < 1) {
        toastify({ "message": "Please enter first name" });
        return
      } else if (inputField.last_name.length < 1) {
        toastify({ "message": "Please enter last name" });
        return
      } else if (inputField.email.length < 1) {
        toastify({ "message": "Please enter email" });
        return
      } else if (!validateEmail(inputField.email)) {
        toastify({ "message": "Please enter a valid email address" });
        return;
      } else if (inputField.password.length < 8) {
        toastify({ "message": "Password must be at least 8 characters" });
        return
      } else if (inputField.password.search(/[a-z]/) < 0) {
        toastify({ "message": "Password must contain at least one lowercase letter" });
        return
      } else if (inputField.password.search(/[A-Z]/) < 0) {
        toastify({ "message": "Password must contain at least one uppercase letter" });
        return
      } else if (inputField.password.search(/[0-9]/) < 0) {
        toastify({ "message": "Password must contain at least one number" });
        return
      }
      setLoading(true);
      var signeduser = await dispatch(fetchData({
        "apiurl": "/auth/signuplandowner",
        "firstname": inputField.first_name,
        "lastname": inputField.last_name,
        "username": "",
        "email": inputField.email,
        "password": inputField.password,
        "role": "landowner"
      }));
      setLoading(false);
      console.log(signeduser)
      if (signeduser && signeduser.payload && signeduser.payload.status == 200) {
        toastify({ message: "New Landowner is successfully registered, Please complete the onboarding process of new landowner" })
        Navigate("/landowners")
      }
      else {
        toastify({ message: signeduser.payload.message })
      }
    }
  }
  const inputsHandler = (e) => {
    setInputField(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
  }
  const [inputField, setInputField] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: ''
  })
  return (

    <div className="login_form_content_new">
      <div className="content_section">
        <form onSubmit={e => e.preventDefault()}>
          <h2>Let’s create an account for Landowner</h2>
          <div className="navigation_row">
          </div>

          <div className="inputs_section">
            <div className="form-row">
              <input
                type="text"
                className="form-control"
                name="first_name"
                id="exampleInputEmail1"
                placeholder="First Name"
                onChange={inputsHandler}
                value={inputField.first_name}
              />
            </div>

            <div className="form-row">
              <input
                type="text"
                name="last_name"
                className="form-control"
                id="exampleInputEmail2"
                placeholder="Last Name"
                onChange={inputsHandler}
                value={inputField.last_name}
              />
            </div>

            <div className="form-row">
              <input
                type="email"
                className="form-control"
                name="email"
                id="exampleInputEmail4"
                aria-describedby="emailHelp"
                placeholder="Email"
                onChange={inputsHandler}
                value={inputField.email}
              />
            </div>

            <div className="">
              <div className="input-group">
                {/* Use input-group to group input and button */}
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  placeholder="Password"
                  onChange={inputsHandler}
                  value={inputField.password}
                />
                <button
                  type="button"
                  className="eye-icon btn btn-outline-secondary"
                  onClick={handleType}
                >
                  {passwordicon ? <i className="fa fa-eye" aria-hidden="true"></i> :
                    <i className="fa fa-eye-slash" aria-hidden="true"></i>}
                </button>
              </div>
            </div>

            <div className="form-row w-100">

              <button
                type="button"
                className="users_btn change-passbtn"
                onClick={signupclick}
              >
                {loading ? <div className="loader"></div> : 'Create Account'}

              </button>

            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewLandowner;
