import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import React, { useState, useEffect } from "react";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout } from '../redux/controller';
import Deleteicon from "../assets/trash.png";
import Xicon from "../assets/Delete Icon.svg"
import { FaCopy } from 'react-icons/fa';

import { Propertyimg } from "./Images"

import 'react-toastify/dist/ReactToastify.css';

export default function Properties() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [propertiesData, setPropertiesData] = useState([]);
  const [selectedproperty, setSelectedproperty] = useState('');
  const [status, setStatus] = useState('Loading...');
  const [searchInput, setSearchInput] = useState("");

  const usertoken = localStorage.getItem('usertoken');

  const [totalReservations, setTotalReservations] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [managehunterstatus, setManagehunterstatus] = useState(false);

  const [perpage, setPerPage] = useState(10);

  const inputperPage = (event) => {
    setCurrentPage(1);
    setPerPage(event.target.value);
  };

  useEffect(() => {
    getProperties(searchInput, currentPage);
  }, [currentPage,perpage]);

  function handleDeleteclick(index) {
    if (index.target.id && index.target.id.split("_")[1]) {
      setSelectedproperty(index.target.id.split("_")[1])
      setManagehunterstatus(true);
    }
  }

  const handleCopyClick = (id) => {
    const shareLink = `https://applinks.doveblasters.com/${id}`;
    navigator.clipboard.writeText(shareLink);
    toastify({ 'message': "Property link copied successfully!" })

  };

  function handlepopupclose() {
    setManagehunterstatus(!managehunterstatus);
  }
  async function handlepopupclosedelete() {

    console.log(selectedproperty)
    var response = await dispatch(fetchData({
      "apiurl": "/property/delete",
      _id: selectedproperty,
      headers: { "x-access-token": usertoken }
    }))
    console.log(response)
    if (response && response.payload && response.payload.status == 200) {
      setManagehunterstatus(!managehunterstatus);
      toastify({ message: "Property Deleted Successfully" })
      getProperties(searchInput, 1);
    }
    else {
      setManagehunterstatus(!managehunterstatus);
      toastify({ message: "Not able to delete hunter" })
    }
  }
  const getProperties = async (searchQuery = "", page = 1) => {
    setPropertiesData([]);
    setStatus('Loading...')
    const propertyList = await dispatch(
      fetchData({
        apiurl: "/property/listAllproperty",
        page: page,
        limit: perpage,
        search: searchQuery,
        headers: { "x-access-token": usertoken }
      })
    );

    console.log("propertyList", propertyList);

    if (propertyList && propertyList.payload && propertyList.payload.status === 200) {
      if (propertyList.payload.totalProperties === 0) {
        setStatus('No properties!');
        setCurrentPage(1)
        setPropertiesData([])
      } else {
        const propertyListTest = [];
        for (let i = 0; i < propertyList.payload.data.length; i++) {
          const element = propertyList.payload.data[i];
          let minPrice = 0;
          let maxPrice = 0;
          let minDate;
          let maxDate;

          let formattedMinDate = '';
          let formattedMaxDate = '';

          if (element.property.area != null) {
            if (element.property.area.length > 0) {
              for (let j = 0; j < element.property.area.length; j++) {
                const elementArea = element.property.area[j];
                for (let k = 0; k < elementArea.dates.length; k++) {
                  const elementDates = elementArea.dates[k];

                  if (!minDate) {
                    minDate = new Date(elementDates.start);
                  }

                  if (!maxDate) {
                    maxDate = new Date(elementDates.end);
                  }

                  if (new Date(elementDates.start) <= minDate) {
                    minDate = new Date(elementDates.start);
                  } else if (new Date(elementDates.end) >= maxDate) {
                    maxDate = new Date(elementDates.end);
                  }

                  if (minPrice === 0) {
                    minPrice = elementDates.price;
                  }
                  if (maxPrice === 0) {
                    maxPrice = elementDates.price;
                  }
                  if (elementDates.price <= minPrice) {
                    minPrice = elementDates.price;
                  } else if (elementDates.price >= maxPrice) {
                    maxPrice = elementDates.price;
                  }
                }
              }

              if (minDate) {
                formattedMinDate = `${String(minDate.getMonth() + 1).padStart(2, '0')}/${String(minDate.getDate()).padStart(2, '0')}/${minDate.getFullYear()}`;
              }
              if (maxDate) {
                formattedMaxDate = `${String(maxDate.getMonth() + 1).padStart(2, '0')}/${String(maxDate.getDate()).padStart(2, '0')}/${maxDate.getFullYear()}`;
              }
            }
          }
          const data = {
            property: element.property,
            id: element.property._id,
            property_name: element.property.title,
            image: element.property.images[0],
            lease: element.property.lease,
            category: element.property.category,
            county: element.property.country,
            city: element.property.city,
            stateLocal: element.property.state,
            zipcode: element.property.zipcode,
            totalarea: element.property.totalarea,
            landownerId: element?.landownername == null ? '' : element.landownername._id,
            landowner_name: element?.landownername == null ? '--' : element?.landownername.firstname + " " + element?.landownername.lastname,
            price: `$${minPrice}-$${maxPrice}`,
            number_of_booking: element.reservationsCount,
            reservation_date: `${formattedMinDate}-${formattedMaxDate}`,
            action: element.property.approved == null ? 'Pending' : element.property.approved === "false" ? 'Rejected' : element.property.approved === "paused" ? 'Paused' : 'Approved',
          };
          propertyListTest.push(data);
        }

        setPropertiesData(propertyListTest);

        setTotalReservations(propertyList.payload.totalProperties);
        setStatus('');
      }
    } else {
      if (propertyList.payload.response && propertyList.payload.response.data && propertyList.payload.response.data.message) {
        if (propertyList.payload.response.data.message === "Invalid token provided!") {
          toastify({ message: 'Session expired' });
          userlogout();
          navigate('/');
        }
      }
    }
  }

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleSearch = () => {
    setPropertiesData([]);
    setStatus('Loading...')
    getProperties(searchInput, 1);
    setCurrentPage(1);
  };

  const handleClearSearch = () => {
    setPropertiesData([]);
    setStatus('Loading...')
    setSearchInput("")
    getProperties("", 1)
    setCurrentPage(1)
    setTotalReservations(0)
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <>
      <div className="properties-main">
        <div className="search-div">
          <div className="input-wrapper">
            <input
              type="text"
              className="form-control hunter-search"
              placeholder="Landowner Name, Property Name"
              value={searchInput}
              onKeyDown={handleKeyDown}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            {searchInput && (
              <button className="clear-btn" onClick={handleClearSearch}>×</button>
            )}
          </div>
          <button className="add-btn" onClick={handleSearch}>Search</button>
        </div>

        <div className="managecoupons-table add-form">
          <div className="add-table property_table">
            <table className="table">
              <thead>
                <tr>
                  <th>Property Name</th>
                  <th>Image</th>
                  <th>Lease</th>
                  <th>Category</th>
                  <th>Landowner Name</th>
                  <th>Price</th>
                  <th>Number of Booking</th>
                  <th>Reservation Date</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {propertiesData.length === 0 ?
                  <td colSpan="9" style={{ textAlign: 'center' }}>
                    <div style={{
                      paddingLeft: '10px',
                      paddingTop: '40px',
                      display: 'inline-block',
                    }}>
                      {status}
                    </div>
                  </td>
                  :
                  propertiesData.map((reservation) => (
                    <tr
                      key={reservation.id}
                      className={
                        reservation.action === "Approved"
                          ? "Action_approved"
                          : reservation.action === "Rejected"
                            ? "Action_reject"
                            : reservation.action === "Pending" || reservation.action === "Paused"
                              ? "Action_pending"
                              : ""
                      }
                    >
                      <td>
                        <Link className="hunter-link" to={`/propertydetails`} state={reservation}>
                          {reservation.property_name}
                        </Link>
                        {reservation.action == "Approved" ?
                          <FaCopy
                            className="copy-icon"
                            onClick={() => handleCopyClick(reservation.id)}
                            style={{ cursor: 'pointer', marginLeft: '10px', color: '#be7637' }}
                            title="Copy link"
                          />
                          : <div></div>
                        }
                      </td>
                      <td>{reservation.image == null ?
                        <img src={Propertyimg} alt="Propertyimg" className="pro-img" />
                        :
                        <img className="pro-img" src={process.env.REACT_APP_PROPERTY_IMAGE_BASEURL + '/' + reservation.image} alt="Property" />
                      }
                      </td>
                      <td>{reservation.lease}</td>
                      <td>{reservation.category}</td>
                      <td>{reservation.landowner_name}</td>
                      <td>{reservation.price}</td>
                      <td>{reservation.number_of_booking}</td>
                      <td>{reservation.reservation_date}</td>
                      <td>
                        <button
                          className="reservation-actionbtn">
                          <i class={
                            reservation.action === "Pending" || reservation.action === "Paused" ?
                              "fa-regular fa-clock" :
                              reservation.action === "Approved"
                                ? "fa-solid fa-circle-check"
                                :
                                "fa-solid fa-circle-xmark"
                          }></i>
                          {reservation.action}
                        </button>
                      </td>
                      <td>
                        <img src={Deleteicon} alt="Deleteicon" id={"deleteproperty_" + reservation.id} onClick={handleDeleteclick} className="delete-icon" />
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
          {managehunterstatus && (
            <div className="managecou-popup">
              <div className="managecou-content">
                <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
                <div className="managecou-contentdiv">
                  <img src={Xicon} alt="Xicon" />
                  <h1>Are you Sure Want to Delete?</h1>
                  <p>Please note that deleting this property will also delete all associated reservations.</p>
                </div>
                <div className="managecou-btn">
                  <button className="btn1" onClick={handlepopupclose}>
                    Cancel
                  </button>
                  <button className="btn2" onClick={handlepopupclosedelete}>
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}


          <div className="rowperpage">
            <div className="perpagecount">
              Row Per Page
              <select
                onChange={inputperPage}
                value={perpage}>
                <option value={10}>10</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
                <option value={150}>150</option>
                <option value={200}>200</option>
              </select>
            </div>
            <ReactPaginate
              pageCount={Math.ceil(totalReservations / perpage)}
              pageRangeDisplayed={perpage}
              breakLabel={"..."}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              activeClassName={"active"}
            />
          </div>


        </div>
      </div>
    </>
  );
}
