import ReactPaginate from "react-paginate";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout } from '../redux/controller';
import 'react-toastify/dist/ReactToastify.css';

export default function LandownersDetails() {

  const location = useLocation();
  const { state } = location;
  const { id, Landowner, email, phone_number, createdAt } = state || {};


  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [landownerId, setLandownerId] = useState("");
  const [landownerName, setLandownerName] = useState("");
  const [landownerEmail, setLandownerEmail] = useState("");
  const [landownerPhone, setLandownerPhone] = useState("");
  const [landownerCreatedAt, setLandownerCreatedAt] = useState("");

  const [searchInput, setSearchInput] = useState("");
  const [landownersdata, setPropertyData] = useState([]);

  const usertoken = localStorage.getItem('usertoken');

  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current === false) {
      if (id) {
      setLandownerId(id)
      setLandownerName(Landowner)
      setLandownerEmail(email)
      setLandownerPhone(phone_number)
      setLandownerCreatedAt(createdAt)

      getAllHunters();
      }else{
        navigate("/")
      }

      return () => {
        effectRan.current = true;
      };
    }
  }, [dispatch, usertoken, navigate]);

  function convertdate(date){
    return date.substring(5, 7)+"/"+date.substring(8, 10)+"/"+date.substring(0, 4)
  }

  const getAllHunters = async (searchQuery = "") => {

    setPropertyData([]);
    setStatus('Loading...')

    var reservationList = await dispatch(
      fetchData({
        apiurl: "/property/listAllproperty",
        page: 1,
        id: id ?? landownerId,
        limit: 10000,
        search: searchQuery,
        headers: { "x-access-token": usertoken }
      })
    );

    if (reservationList && reservationList.payload && reservationList.payload.status === 200) {
      if (reservationList.payload.data && reservationList.payload.data.length) {
        const propertyListTest = [];
        for (let i = 0; i < reservationList.payload.data.length; i++) {

          const element = reservationList.payload.data[i];
          let totalArea = 0;
          let totalSlots = 0;
          if (element.property.area != null) {
            if (element.property.area.length > 0) {
              for (let j = 0; j < element.property.area.length; j++) {
                totalArea = totalArea + 1;
                totalSlots = totalSlots + element.property.area[j].slots.length;

              }
            }
          }
          const data = {
            category: element.property.category,
            county: element.property.country,
            city: element.property.city,
            stateLocal: element.property.state,
            zipcode: element.property.zipcode,
            totalarea: element.property.totalarea,
            property: element.property,
            totalArea: totalArea,
            totalSlots: totalSlots,
            id: element.property._id,
            property_name: element.property.title,
            no_of_booking: element.reservationsCount,
          };
          propertyListTest.push(data);
        }

        setPropertyData(propertyListTest);
      } else {
        setStatus('No properties!')
      }
    } else {
      if (reservationList.payload.response && reservationList.payload.response.data && reservationList.payload.response.data.message) {
        if (reservationList.payload.response.data.message === "Invalid token provided!") {
          toastify({ message: 'Session expired' });
          userlogout();
          navigate('/');
        }
      }
    }
  }

  const handleSearch = () => {
    getAllHunters(searchInput);
  };

  const handleClearSearch = () => {
    setSearchInput("");
    getAllHunters();
  };

  const handlereservationupcomingidPageClick = (selectedPage) => {
    setReservationupcomingidcurrentPage(selectedPage.selected);
  };

  const [status, setStatus] = useState('Loading...');

  // Calculate start and end index for the current page
  const [
    reservationupcomingidcurrentPage,
    setReservationupcomingidcurrentPage
  ] = useState(0);
  const reservationupcomingidperPage = 10;
  const reservationupcomingidstartIndex =
    reservationupcomingidcurrentPage * reservationupcomingidperPage;
  const reservationupcomingidendIndex =
    reservationupcomingidstartIndex + reservationupcomingidperPage;

  // Get data for the current page
  const reservationupcomingidcurrentData = landownersdata.slice(
    reservationupcomingidstartIndex,
    reservationupcomingidendIndex
  );
  return (
    <div className="landowner-ditailsdiv">
      <div className="reservation-idtable">
        <div className="search-div">
          <div className="input-wrapper">
            <input
              type="text"
              className="form-control hunter-search"
              placeholder="Landowner Name, Property Name"

              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            {searchInput && (
              <button className="clear-btn" onClick={handleClearSearch}>×</button>
            )}
          </div>
          <button className="add-btn" onClick={handleSearch}>Search</button>
        </div>
        <div className="reservation_divider">
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Name</h1>
              <p>{landownerName}</p>
            </div>
          </div>
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Email ID</h1>
              <p>{landownerEmail}</p>
            </div>
          </div>
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Phone Number</h1>
              <p>{landownerPhone}</p>
            </div>
          </div>
          <div className="reservation_part">
            <div className="reservation_content">
              <h1>Account Created</h1>
              {/* <p>{`${new Date(landownerCreatedAt).toLocaleDateString()}`}</p> */}
              <p>
                {/* {new Date(landownerCreatedAt).toLocaleDateString('en-US', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })} */}
                    {convertdate(landownerCreatedAt)}
                  </p>
            </div>
          </div>
        </div>
        <div className="add-form pagination-table">
          <div className="add-table">
            <table className="table">
              <thead>
                <tr>
                  <th>Property Name</th>
                  <th>Number of Area</th>
                  <th>Number of Slots</th>
                  <th>Number of Booking</th>
                </tr>
              </thead>
              <tbody>
                {reservationupcomingidcurrentData.length === 0 ?
                  <td colSpan="4" style={{ textAlign: 'center' }}>
                    <div style={{
                      paddingLeft: '10px',
                      paddingTop: '40px',
                      display: 'inline-block',
                    }}>
                      {status}
                    </div>
                  </td>
                  :
                  reservationupcomingidcurrentData.map((reservation) => (
                    <tr key={reservation.id} className="upcoming_cancel">
                      {/* <td><Link className="hunter-link" to={`/propertydetails`} state={reservation} >{reservation.property_name}</Link></td> */}
                      <td>{reservation.property_name}</td>
                      <td>{reservation.totalArea}</td>
                      <td>{reservation.totalSlots}</td>
                      <td>{reservation.no_of_booking}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <ReactPaginate
            pageCount={Math.ceil(
              landownersdata.length / reservationupcomingidperPage
            )}
            pageRangeDisplayed={10}
            breakLabel={"..."}
            marginPagesDisplayed={2}
            onPageChange={handlereservationupcomingidPageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
}

