
import { Editicon } from "../Pages/Images"
import React, { useEffect, useState, useRef } from "react";
import "./App.css";
import Profileimg from "../assets/profile1.svg";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify } from "../redux/controller";


export default function Editprofile() {

  const [stepinputField, setstepInputField] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phonenumber: "",
    stripeemail: "",
    livein: "",
    website: "",
    aboutme: ""
  });

  const Navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUpdateprofile = async () => {
    if(stepinputField.firstname.length<1){
      toastify({ "message": "Please enter first name" });
      return
    }else if (stepinputField.lastname.length<1){
      toastify({ "message": "Please enter last name" });
      return
    }else if (stepinputField.phonenumber.length< 1){
      toastify({ "message": "Please enter phone number" });
      return
    }

    var body = {
      "apiurl": "/auth/update/profile",
      "firstname": stepinputField.firstname,
      "lastname": stepinputField.lastname,
      "phonenumber": stepinputField.phonenumber,
      "livein": stepinputField.livein,
      "website": stepinputField.website,
      "aboutme": stepinputField.aboutme,
      "stripeemail": stepinputField.stripeemail,
      headers: { "x-access-token": usertoken }
    }
    handleUpdateprofilesave(body)
  };

  const handleUpdateprofilediscart = () => {
    Navigate("/profile");
  };

  const [userdata, setuserdata] = useState({});

  const userinfo = JSON.parse(localStorage.getItem('userdata')); 
  const  usertoken = localStorage.getItem('usertoken'); 

  useEffect(() => {
    if (userinfo) {
      setuserdata(userinfo)
      setstepInputField((prevState) => ({
        ...prevState,
        ['firstname']: userinfo.firstname
      }));
      setstepInputField((prevState) => ({
        ...prevState,
        ['lastname']: userinfo.lastname
      }));
      setstepInputField((prevState) => ({
        ...prevState,
        ['email']: userinfo.email
      }));
      setstepInputField((prevState) => ({
        ...prevState,
        ['phonenumber']: userinfo.phonenumber
      }));
      setstepInputField((prevState) => ({
        ...prevState,
        ['livein']: userinfo.livein
      }));
      setstepInputField((prevState) => ({
        ...prevState,
        ['stripeemail']: userinfo.stripeemail
      }));
      setstepInputField((prevState) => ({
        ...prevState,
        ['website']: userinfo.website
      }));
      setstepInputField((prevState) => ({
        ...prevState,
        ['aboutme']: userinfo.aboutme
      }));
    }
  }, [])

  const inputsHandler = (e) => {
    console.log(e.target.name);
    setstepInputField((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value
    }));
  };

  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  const fileInputRef = useRef(null);


  const handleFileChange = async (e) => {
    const files = e.target.files[0];

    const formData = new FormData();
    formData.append("user-image", files)
    const response = await fetch(process.env.REACT_APP_BASEURL + '/upload/user-image-single', {
      method: "post",
      body: formData,
    });
    console.log(response.status)
    if (response.status === 200 || response.status === 201) {
      const data = await response.json();
      console.log("data")
      console.log(data.data)

      var body = {
        "apiurl": "/auth/update/profile",
        "image": data.data,
        headers: { "x-access-token": usertoken }
      }
      handleUpdateprofilesave(body)
    }

  };

  const handleUpdateprofilesave = async (body) => {
    var profile = await dispatch(fetchData(body))
    if (profile && profile.payload && profile.payload.status === 200) {
       localStorage.setItem("userdata", JSON.stringify(profile.payload.data.user))
      toastify({ "message": profile.payload.message })
      Navigate("/profile");
    }
    else {
      toastify({ "message": profile.payload.message })
    }
  }

  return (
    <div className="editprofile-main">
      <div className="profile-details">
        <div className="profile-top">
          <h1>Profile Details</h1>
        </div>
        <div className="profile-details-main">
        <div className="profile-details-content edit-content">
            <div className="first-part">
              <p>Profile Image</p>
            </div>
            <div className="second-part edit-img">
            {userdata.image?<img  className="Profile_img" src={process.env.REACT_APP_BASEURL+"/"+userdata.image} alt="Profileimg" /> :<img className="Profile_img" src={Profileimg} alt="Profileimg" />}
              <img className="Editicon" src={Editicon} alt="Editicon"  onClick={handleDivClick} />
              <input
                type="file"
                className="file-input"
                onChange={handleFileChange}
                ref={fileInputRef}
                accept="image/png, image/gif, image/jpeg"
              />
            </div>
          </div>

          <div className="profile-details-content edit-content">
            <div className="first-part">
              <p>First Name</p>
            </div>
            <div className="second-part">
              <input
                type="text"
                className="form-control"
                placeholder="Enter First Name"
                name="firstname"
                onChange={inputsHandler}
                value={stepinputField.firstname}
              />
            </div>
          </div>

          <div className="profile-details-content edit-content">
            <div className="first-part">
              <p>Last Name</p>
            </div>
            <div className="second-part">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Last Name"
                name="lastname"
                onChange={inputsHandler}
                value={stepinputField.lastname}
              />
            </div>
          </div>

          <div className="profile-details-content edit-content">
            <div className="first-part">
              <p>Email Address</p>
            </div>
            <div className="second-part">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email Address "
                name="email"
                readOnly = "true"
                onChange={inputsHandler}
                value={stepinputField.email}
              />
            </div>
          </div>

          {/* <div className="profile-details-content edit-content">
            <div className="first-part">
              <p className="email-bottom">Email for receiving paypal payment</p>
              <span>(booking fees, refund security fees, etc)</span>
            </div>
            <div className="second-part">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Email for receiving paypal payment"
                name="stripeemail"
                onChange={inputsHandler}
                value={stepinputField.stripeemail}
              />
            </div>
          </div> */}

          <div className="profile-details-content edit-content">
            <div className="first-part">
              <p>Phone Number </p>
            </div>
            <div className="second-part">
              <input
                type="number"
                className="form-control"
                placeholder="Enter Phone Number"
                name="phonenumber"
                onChange={inputsHandler}
                value={stepinputField.phonenumber}
              />
            </div>
          </div>



          <div className="profile-details-content edit-content">
            <div className="first-part">
              <p>I Live in</p>
            </div>
            <div className="second-part">
              <input
                type="text"
                className="form-control"
                placeholder="Enter live in address"
                name="livein"
                onChange={inputsHandler}
                value={stepinputField.livein}
              />
            </div>
          </div>

          <div className="profile-details-content edit-content">
            <div className="first-part">
              <p>Website</p>
            </div>
            <div className="second-part">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Website"
                name="website"
                onChange={inputsHandler}
                value={stepinputField.website}
              />
            </div>
          </div>

          <div className="profile-details-content edit-content">
            <div className="first-part">
              <p>About Me</p>
            </div>
            <div className="second-part">
              <div className="form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="floatingTextarea2"
                  name="aboutme"
                  onChange={inputsHandler}
                  value={stepinputField.aboutme}
                ></textarea>
                <label for="floatingTextarea2">Type here...</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="editpro-container">
        <button className="Discard" onClick={handleUpdateprofilediscart}>
          Discard
        </button>
        <button className="Update" onClick={handleUpdateprofile}>
          Update Profile
        </button>
      </div>
    </div>
  );
}
