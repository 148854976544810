import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Profileimg, Locationicon, Messageicon } from "../Pages/Images"

export default function Profile() {

  const Navigate = useNavigate();

  const handleEditprofile = () => {
    Navigate("/editprofile");
  };
  const [userdata, setuserdata] = useState({});
  const usertoken = localStorage.getItem('usertoken');

  const userinfo = JSON.parse(localStorage.getItem('userdata'));



  useEffect(() => {
    if (userinfo) {
      setuserdata(userinfo)
    }
  }, [])
  return (
    <div className="profile-main">
      <div className="profile-name">
        <div className="profile-img">
          {userdata.image ? <img src={process.env.REACT_APP_BASEURL + "/" + userdata.image} alt="Profileimg" /> : <img src={Profileimg} alt="Profileimg" />}
        </div>

        <div className="profile-contentmain">
          <h1>{userdata.firstname} {userdata.lastname}</h1>
          {/* <h1>Stern Aldrich</h1> */}
          <div className="profile-content">
            {
              (userdata.livein) && (
                <div className="profile-content-icon">
                  <img src={Locationicon} alt="Locationicon" />
                  <p>
                    {userdata.livein}
                  </p>
                </div>
              )
            }
            <div className="profile-content-icon">
              <img src={Messageicon} alt="Messageicon" />
              <p>{userdata.email}</p>
              {/* <p>stern@wyldeguitars.com</p> */}
            </div>
          </div>
        </div>
      </div>

      <div className="profile-details">
        <div className="profile-top">
          <h1>Profile Details</h1>
          <button onClick={handleEditprofile}>Edit Profile</button>
        </div>

        <div className="profile-details-main">
          <div className="profile-details-content">
            <div className="first-part">
              <p>First Name:</p>
            </div>
            <div className="second-part">
              <p>{userdata.firstname}</p>
              {/* <p>Stern</p> */}
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>Last Name:</p>
            </div>
            <div className="second-part">
              <p>{userdata.lastname}</p>
              {/* <p>Aldrich</p> */}
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>Email Address:</p>
            </div>
            <div className="second-part">
              <p>{userdata.email}</p>
              {/* <p>Stern@wyldeguitars.com</p> */}
            </div>
          </div>

          {/* <div className="profile-details-content">
            <div className="first-part">
              <p className="first-part-span">Email for receiving paypal payment</p>
              <span>(booking fees, refund security fees, etc)</span>
            </div>
            <div className="second-part">
              <p>{userdata.stripeemail}</p>
            </div>
          </div> */}

          <div className="profile-details-content">
            <div className="first-part">
              <p>Phone Number:</p>
            </div>
            <div className="second-part">
              <p>{userdata.phonenumber}</p>
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>I live in:</p>
            </div>
            <div className="second-part">
              <p>{userdata.livein}</p>
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>Website:</p>
            </div>
            <div className="second-part">
              <p>{userdata.website}</p>
            </div>
          </div>

          <div className="profile-details-content">
            <div className="first-part">
              <p>About Me:</p>
            </div>
            <div className="second-part">
              <p>{userdata.aboutme}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
