import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";

import React, { useState, useEffect } from "react";
import "./style.css";
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/apiSlice";
import { toastify, userlogout } from '../redux/controller';
import 'react-toastify/dist/ReactToastify.css';
import Deleteicon from "../assets/trash.png";
import Editicon from "../assets/pencil.png";
import Xicon from "../assets/Delete Icon.svg"

export default function Hunters() {

  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [perpage, setPerPage] = useState(10);

  const [managehunterstatus, setManagehunterstatus] = useState(false);
  const [totalHunters, setTotalHunter] = useState(0);
  const [selectedproperty, setSelectedproperty] = useState('');
  const [membertype, setmembertype] = useState('');
  const [status, setStatus] = useState('Loading...');
  const [Reservationsdata, setReservationsdata] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const usertoken = localStorage.getItem('usertoken');

  const [currentPage, setCurrentPage] = useState(1);
  // Users count
  const [membershipusers, setmembershipusers] = useState([]);
  const [membershipuserscount, setmembershipuserscount] = useState(0);
  const [totalhunters, settotalhunters] = useState([]);

  const [loadingDetails, setLoadingDetails] = useState(true);

  function handlepopupclose() {
    setManagehunterstatus(!managehunterstatus);
  }
  async function handlepopupclosedelete() {

    console.log(selectedproperty)
    var response = await dispatch(fetchData({
      "apiurl": "/auth/user/deletehunter",
      hunterid: selectedproperty,
      headers: { "x-access-token": usertoken }
    }))
    console.log(response)
    if (response && response.payload && response.payload.status == 200) {
      setManagehunterstatus(!managehunterstatus);
      toastify({ message: response.payload.message })
      getAllHunters("", 1)
    } else if (response && response.payload && response.payload.status == 400) {
      setManagehunterstatus(!managehunterstatus);
      toastify({ message: response.payload.message })
    }
    else {
      setManagehunterstatus(!managehunterstatus);
      toastify({ message: "Not able to delete hunter" })
    }
  }

  function changemembertype(e) {
    setmembertype(e.target.value)
    setCurrentPage(1);
  }

  const inputperPage = (event) => {
    setPerPage(event.target.value);
    setReservationsdata([]);
    setStatus('Loading...');
    setCurrentPage(1);
  };

  const getAllHunters = async (searchQuery = "", page = 1) => {

    setReservationsdata([]);
    setStatus('Loading...');
    var hunterlist = await dispatch(
      fetchData({
        apiurl: "/auth/user/allhunters",
        page: page,
        limit: perpage,
        search: searchQuery,
        membertype: membertype,
        headers: { "x-access-token": usertoken }
      })
    );

    if (hunterlist && hunterlist.payload && hunterlist.payload.status === 200) {

      setReservationsdata([]);
      if (hunterlist.payload.totalHunters === 0) {
        setStatus('No Hunter!');
        setCurrentPage(1)
        setTotalHunter(0)
      } else {
        const huntersListLocal = hunterlist.payload.hunters.map((hunters) => ({
          id: hunters.user._id,
          hunter_name: hunters.user.firstname + ' ' + hunters.user.lastname,
          email: hunters.user.email ?? '--',
          phone_number: hunters.user.phonenumber != null ? hunters.user.phonenumber.length > 0 ? hunters.user.phonenumber : '--' : '--',
          number_of_reservations: hunters.reservations.length,
          subscriptions: hunters.user.subscriptions,
          action: hunters.user.creditCard != null ? (hunters.user.creditCard.cardNumber != null ? 'Completed' : 'Pending') : 'Pending',
          createdAt: hunters.user.createdAt ?? '--'
        }));
        setReservationsdata(huntersListLocal);
        setTotalHunter(hunterlist.payload.totalHunters);
        setStatus('');
      }
    } else if (hunterlist.payload.response && hunterlist.payload.response.message) {
      if (hunterlist.payload.response.message === "Invalid token provided!") {
        toastify({ message: 'Session expired' });
        userlogout();
        navigate('/');
      }
    } else {
      setStatus('No Hunter!');
    }
  };

  useEffect(() => {
    getallhuntercount()
  }, []);
  useEffect(() => {
    getAllHunters(searchInput, currentPage);
  }, [currentPage, membertype,perpage]);

  async function getallhuntercount() {

    var huntercount = await dispatch(
      fetchData({
        apiurl: "/auth/users/count",
        headers: { "x-access-token": usertoken }
      })
    );
    console.log(huntercount)
    setLoadingDetails(false)
    if (huntercount && huntercount.payload && huntercount.payload.status === 200) {
      setmembershipusers(huntercount.payload.membershipusers)
      setmembershipuserscount(huntercount.payload.membershipuserscount)
      settotalhunters(huntercount.payload.totalhunters)
    }
  }

  function handleDeleteclick(index) {
    if (index.target.id && index.target.id.split("_")[1]) {
      setSelectedproperty(index.target.id.split("_")[1])
      setManagehunterstatus(true);
    }
  }

  const handleEdit = (e) => {
    // console.log(arrayDataItems)
    // if (e.target.id.split("_")[1]) {
    //   Navigate("/propertyreview?index=" + e.target.id.split("_")[1]);
    // }
  };

  const handlePageClick = (selectedPage) => {
    console.log("Selected page ", selectedPage.selected + 1);
    setCurrentPage(selectedPage.selected + 1);
  };

  const handleSearch = () => {

    setReservationsdata([]);
    setStatus('Loading...');
    getAllHunters(searchInput, 1);
    setCurrentPage(1);
  };

  const handleAddHunter = () => {
    navigate("/newhunter")
  };

  const handleClearSearch = () => {
    setReservationsdata([]);
    setStatus('Loading...');
    setSearchInput("")
    getAllHunters("", 1)
    setCurrentPage(1)
    setTotalHunter(0)
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="hunter-main">

      <div className="dashboard-main">
        <div className="dashboard-section">
          <div className="dashboard-subsections" >
            <div className="dashboard-contant">
              <p className="title">Total Hunters</p>
              <h3>{loadingDetails ? <span className="loading-dots">.</span> : totalhunters.length}</h3>
            </div>
            <div className="dashboard-contant">
              <p className="title">Total Members</p>
              <h3>{loadingDetails ? <span className="loading-dots">.</span> : membershipusers.length}</h3>
            </div>
            <div className="dashboard-contant">
              <p className="title">Memberships</p>
              <h3>{loadingDetails ? <span className="loading-dots">.</span> : membershipuserscount}</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="search-div">
        <select className="membertype" onChange={changemembertype}>
          <option value={''}>ALL</option>
          <option value={'membership'}>Membership</option>
          <option value={'direct'}>Direct</option>
        </select>

        <div className="hunder_head_row_tabel">
          <div className="input-wrapper">
            <input
              type="text"
              className="form-control hunter-search"
              placeholder="Hunter Name, Email, Phone Number"
              onKeyDown={handleKeyDown}
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            {searchInput && (
              <button className="clear-btn" onClick={handleClearSearch}>×</button>
            )}
          </div>
          <button className="add-btn" onClick={handleSearch}>Search</button>
          <button className="add-btn-hunter" onClick={handleAddHunter}>+ Add Hunter</button>
        </div>
      </div>

      <div className="managecoupons-table add-form">
        <div className="add-table">
          <table className="table">
            <thead>
              <tr>
                {/* <th>Sr No.</th> */}
                <th>Hunter Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Total Reservations</th>
                <th>Membership Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {Reservationsdata.length === 0 ?
                <td colSpan="5" style={{ textAlign: 'center' }}>
                  <div style={{
                    paddingLeft: '10px',
                    paddingTop: '40px',
                    display: 'inline-block',
                  }}>
                    {status}
                  </div>
                </td>
                :
                Reservationsdata.map((reservation,index) => (
                  <tr
                    key={reservation.id}
                    className={
                      reservation.action === "Pending"
                        ? "Action_cancelled"
                        : reservation.action === "Completed"
                          ? "Action_completed"
                          : ""
                    }
                  >
                    {/* <td>{index+1}</td> */}
                    <td>
                      <Link
                        className="hunter-link"
                        to={`/huntersdetails`}
                        state={reservation}
                        style={{
                          textDecoration: 'underline',
                          color: '#be7637'
                        }}
                      >{reservation.hunter_name}</Link></td>
                    <td>{reservation.email}</td>
                    <td>{reservation.phone_number}</td>
                    <td>
                      <Link
                        className="hunter-link"
                        to={`/huntersdetails`}
                        state={reservation}
                        style={{
                          textDecoration: 'underline',
                          color: '#be7637'
                        }}
                      >
                        {reservation.number_of_reservations}
                      </Link>
                    </td>
                    <td>
                      {reservation && reservation.subscriptions && reservation.subscriptions.length && reservation.subscriptions[0].no_of_memberships ? "Membership" : "Direct"}
                      {/* <button
                        className="reservation-actionbtn"
                        onClick={() =>
                          reservation.action.toLowerCase() === "Cancelled"
                        }
                      >
                        <i class={
                          reservation.action === "Completed"
                            ? "fa-solid fa-circle-check"
                            : "fa-regular fa-clock"
                        }></i>
                        {reservation.action}
                      </button> */}

                    </td>
                    <td>
                      <img src={Deleteicon} alt="Deleteicon" id={"deletehunter_" + reservation.id} onClick={handleDeleteclick} className="delete-icon" />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        {managehunterstatus && (
          <div className="managecou-popup">
            <div className="managecou-content">
              <i className="fa-solid fa-xmark" onClick={handlepopupclose}></i>
              <div className="managecou-contentdiv">
                <img src={Xicon} alt="Xicon" />
                <h1>Are you Sure Want to Delete?</h1>
                <p>This will result in the deletion of all reservations associated with this Hunter.</p>
              </div>
              <div className="managecou-btn">
                <button className="btn1" onClick={handlepopupclose}>
                  Cancel
                </button>
                <button className="btn2" onClick={handlepopupclosedelete}>
                  Delete
                </button>
              </div>
            </div>
          </div>
        )}



        <div className="rowperpage">
          <div className="perpagecount">
            Row Per Page
            <select
              onChange={inputperPage}
              value={perpage}>
              <option value={10}>10</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={150}>150</option>
              <option value={200}>200</option>
            </select>
          </div>
          <ReactPaginate
            pageCount={Math.ceil(totalHunters / perpage)}
            pageRangeDisplayed={perpage}
            breakLabel={"..."}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        </div>
      </div>
    </div>
  );
}
