import React, { useState } from "react";
import { Mainlogo } from "../Pages/Images"
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { fetchData } from '../redux/apiSlice';
import { toastify, userlogin, userlogout } from '../redux/controller';

const Login = () => {
  const dispatch = useDispatch()

  const [inputField, setInputField] = useState({
    email: '',
    password: ''
  })

  const [loading, setLoading] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const inputsHandler = (e) => {
    setInputField(prevState => ({ ...prevState, [e.target.name]: e.target.value }))
  }

  let Navigate = useNavigate();

  // const handleLogin = async () => {
  //   Navigate("/dashboard");
  //   // var signeduser = await dispatch(fetchData({
  //   //     "apiurl": "/auth/login",
  //   //     "email": inputField.email,
  //   //     "password": inputField.password
  //   // }, ));
  //   // console.log(signeduser)
  //   // if(signeduser && signeduser.payload && signeduser.payload.status == 200){
  //   //    dispatch(userlogin(signeduser.payload.data))
  //   // }
  // };

  const handleType = (e) => {
    const inputElement = e.target.parentElement.parentNode.getElementsByTagName('input')[0];
    if (inputElement.type === 'text') {
      inputElement.type = 'password';
    } else {
      inputElement.type = 'text';
    }
    setIsPasswordVisible(!isPasswordVisible);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = async () => {
    // Navigate("/dashboard");
    if (inputField.email.length < 1) {
      toastify({ 'message': "Please enter email" })
      return
    } else if (!validateEmail(inputField.email)) {
      toastify({ "message": "Please enter a valid email address" });
      return;
    } else if (inputField.password.length < 1) {
      toastify({ 'message': "Please enter password" })
      return
    }
    setLoading(true);
    var signeduser = await dispatch(fetchData({
      "apiurl": "/auth/login",
      "email": inputField.email,
      "password": inputField.password,
      "role": "admin"
    }));
    console.log("signeduser", signeduser)
    if (signeduser && signeduser.payload && signeduser.payload.status === 200) {
      // console.log(signeduser.payload.data,"signeduser.payload.data");
      dispatch(userlogin(signeduser.payload.data))

      Navigate("/dashboard");
    }
    else {
      setLoading(false);
      userlogout()
      toastify({ message: signeduser.payload.message })
    }
  };

  return (
    <div class="login_section">
      <div class="w-100 text-center">
        <div class="row">
          <div class="col-lg-7 col-sm-12">
            <div className="login_form_content">
              <div className="content_section">
                <form onSubmit={e => e.preventDefault()}>
                  <h2>Welcome to Admin Panel</h2>
                  <h5>Login into your account</h5>
                  <div className="form-row">
                    <label
                      htmlFor="exampleInputEmail1"
                      className="form-label"
                    ></label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Email"
                      name="email"
                      onChange={inputsHandler}
                      value={inputField.email}
                    />
                  </div>
                  <div className="form-row">
                    <div className="input-group">

                      <input
                        type="password"
                        id="password"
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        onChange={inputsHandler}
                        value={inputField.password}
                      />
                      <button
                        type="button"
                        className="eye-icon btn btn-outline-secondary"
                        onClick={handleType}
                      >
                        <i className={`fa ${isPasswordVisible ? 'fa-eye' : 'fa-eye-slash'}`}></i>
                      </button>
                    </div>
                  </div>
                  <div className="form-row w-100 login_btn_colum">
                    <button
                      type="submit"
                      className="users_btn change-passbtn "

                      onClick={handleLogin}
                    >

                      {loading ? <div className="loader"></div> :  'Login'}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-sm-12">
            <div className="image_section">
              <img src={Mainlogo} alt="Mainlogo"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
