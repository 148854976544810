import { configureStore } from '@reduxjs/toolkit';
import apiReducer from './apiSlice';
import userdataReducer from './controller';

export const store = configureStore({
  reducer: {
    api: apiReducer,
    userdata: userdataReducer,
    // Add other reducers if needed
  },
});